import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../_helpers/fetch-wrapper';
import { toast } from 'react-toastify'

// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}

function createReducers() {
    const baseUrl = process.env.REACT_APP_URL;
    return { logout };
    function logout(state) {
        fetch(`${baseUrl}/logout`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.user.token
            }
        })
        state.user = null;
        localStorage.removeItem('user');
        toast.success('Logout successful!', { position: 'bottom-left' })
    }
}

function createExtraActions() {
    const baseUrl = process.env.REACT_APP_URL;

    return {
        login: login()
    };

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async ({ email, password }) => await fetchWrapper.post(`${baseUrl}/login`, { email, password })
        );
    }
}

function createExtraReducers() {
    return (builder) => {
        login()
        // logout()
        function login() {
            var { pending, fulfilled, rejected } = extraActions.login;
            builder
                .addCase(pending, (state) => {
                    state.error = null;
                    // toast.error('Login NOT successful! ', { position: 'bottom-left' })
                })
                .addCase(fulfilled, (state, action) => {
                    const user = action.payload
                    // store user details and basic auth data in local storage to keep user logged in between page refreshes
                    localStorage.setItem('user', JSON.stringify(user));
                    state.user = user;
                    toast.success('Login successful!', { position: 'bottom-left' })
                })
                .addCase(rejected, (state, action) => {
                    state.error = action.error;
                    toast.error('Login failed! ' + state.error.message, { position: 'bottom-left' })
                });
        }
    }
}