import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Spinner, Pagination, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import MeetingAddNewModal from './crudModals/MeetingAddNewModal';
import MeetingsCardBig from './MeetingsCardBig';
import MeetingsCard from './MeetingsCard';
import { parse } from 'date-fns';

const MeetingsComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [meetings, setMeetings] = useState([]);
    const [nextMeeting, setNextMeeting] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [meetingsPerPage, setMeetingsPerPage] = useState(12);
    const [searchTerm, setSearchTerm] = useState('');
    const { user: authUser } = useSelector(x => x.auth);
    const url = process.env.REACT_APP_URL;

    const getMeetings = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${url}/meetings`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setMeetings(data);
        } catch (error) {
            console.error('Error fetching meetings: ', error);
        }
        setIsLoading(false);
    };

    const getNextMeeting = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${url}/meetings/comingup`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setNextMeeting(data);
        } catch (e) {
            console.log(`Meetings component - Next Meeting error: ${e}`);
        }
    };

    useEffect(() => {
        getMeetings();
        getNextMeeting();
        // eslint-disable-next-line
    }, []);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const parsedMeetings = meetings.map(meeting => ({
        ...meeting,
        parsedDate: parse(meeting.meetingDate, 'dd/MM/yyyy', new Date())
    }));

    const sortedMeetings = parsedMeetings.sort((a, b) => b.parsedDate - a.parsedDate);

    const filteredMeetings = sortedMeetings.filter(meeting =>
        meeting.meetingTheme.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastMeeting = currentPage * meetingsPerPage;
    const indexOfFirstMeeting = indexOfLastMeeting - meetingsPerPage;
    const currentMeetings = filteredMeetings.slice(indexOfFirstMeeting, indexOfLastMeeting);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleMeetingsPerPageChange = (e) => {
        setMeetingsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page when changing results per page
    };

    if (isLoading) {
        return (
            <section className="meetings" id="meetings">
                <Spinner animation="border" variant="danger" />
            </section>
        );
    } else {
        return (
            <section className="meetings" id="meetings">
                <Container>
                    <Row className='header'>
                        <Col xs={12} lg={6} className='title'><h3>All Meetings</h3></Col>
                        {(authUser && authUser.user.acl === 'Officer') &&
                            <Col xs={12} lg={6} key={'newMeeting'}>
                                <MeetingAddNewModal getMeetings={getMeetings} />
                            </Col>
                        }
                    </Row>
                    <Row className='mb-3'>
                        <Col xs={12} lg={12}>
                            <Form.Control
                                type="text"
                                placeholder="Search for a meeting..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                data-bs-theme={'dark'}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {currentMeetings.map(meeting => (
                            meeting._id === nextMeeting?._id ? (
                                <MeetingsCardBig meeting={meeting} getMeetings={getMeetings} key={meeting._id} />
                            ) : (
                                <MeetingsCard meeting={meeting} getMeetings={getMeetings} key={meeting._id} />
                            )
                        ))}
                    </Row>
                    <Row>
                        <Col>
                            <Pagination data-bs-theme={'dark'}>
                                {Array.from({ length: Math.ceil(filteredMeetings.length / meetingsPerPage) }, (_, i) => (
                                    <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
                                        {i + 1}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </Col>
                        <Col xs="auto">
                            <Form.Select value={meetingsPerPage} onChange={handleMeetingsPerPageChange} data-bs-theme={'dark'}>
                                <option value={12}>12 per page</option>
                                <option value={24}>24 per page</option>
                                <option value={36}>36 per page</option>
                            </Form.Select>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
};

export default MeetingsComponent;