import { Col, Row, Image } from "react-bootstrap";
import userImg from '../../../assets/user.png'
import { useContext } from 'react';
import { ProfileContext } from '../../../pages/Profile';

export default function Avatar() {

  const { profile } = useContext(ProfileContext)
  // console.log(profile)
  return (
    <Row className='avatar'>
      <Col className='hexagon'>

        {!profile.avatar
          ? <Image src={userImg} style={{ marginTop: '10px', marginLeft: '10px', height: '180px', width: '180px' }} />
          : <Image src={'data:image/png;base64, ' + profile.avatar} />
        }
      </Col>
    </Row>
  )
}