import { useState } from "react"
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap"
import { useParams } from 'react-router-dom'
import { Link } from "react-router-dom"
import { ArrowRightCircle } from 'react-bootstrap-icons'
import headerImg from '../assets/logos/BTMlogo.png'
import 'animate.css';
import { NavBar } from "../components/general/NavBar"
import { Footer } from "components/general/Footer";

export default function NotFoundPage() {
  const [password, setPassword] = useState('')
  const [passwordconfirm, setPasswordConfirm] = useState('')
  const [errmsg, setErrmsg] = useState('')
  const [saveLoading, setSaveLoading] = useState(false)
  const [messageColor, setMessageColor] = useState('text-danger')

  const url = process.env.REACT_APP_URL
  const params = useParams()
  const token = params.token

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaveLoading(true);
    try {
      setErrmsg('')
      if (password !== passwordconfirm) {
        setMessageColor('text-danger')
        setErrmsg('Passwords do not match')
        setSaveLoading(false)
        return
      }
      const newPassword = password
      const response = await fetch(`${url}/reset/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ newPassword })
      })
      const result = await response.json()
      console.log(response.status)
      if (response.status === 200) {
        setErrmsg(result.message)
        setMessageColor('text-success')
      }
      else {
        setMessageColor('text-danger')
        setErrmsg(result.Error)
      }
    }
    catch (error) {
      console.log(error)
      setMessageColor('text-danger')
      setErrmsg(error)
    }
    setSaveLoading(false);
  }
  return (
    <>
      <NavBar pagename="404" />
      <section className="banner" id="home" style={{ height: '80vh' }}>
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={6} xl={5} className="mb-60" >
              <div className="animate__animated animate__zoomIn">
                <img src={headerImg} alt="Header Img" />
              </div>
            </Col>
            <Col xs={12} md={6} xl={7}>
              <div className="animate__animated animate__fadeIn">
                {/* <h1 className="animate__animated animate__slideInUp"><span className="bold">Password reset</span></h1> */}

                <Card data-bs-theme={'dark'} className="border-top-bottom subtitle mb-30 mt-30 animate__animated animate__slideInUp">
                  <Form>
                    <Card.Header>
                      <Row className="mt-3 mb-3"><Col>Reset Password</Col></Row>
                    </Card.Header>
                    <Card.Body>
                      <Container>
                        <Row className="mt-2 mb-3">
                          <Col>
                            <Row className="mt-3 mb-3">
                              <Col>
                                <Form.Control
                                  type="password"
                                  className="formInput"
                                  placeholder="New Password*"
                                  aria-label="New Password*"
                                  name="password"
                                  value={password}
                                  onChange={e => setPassword(e.target.value)}
                                />
                              </Col>
                            </Row>
                            <Row className="mb-3">
                              <Col>
                                <Form.Control
                                  type="password"
                                  className="formInput"
                                  placeholder="Confirm Password*"
                                  aria-label="Confirm Password*"
                                  name="passwordConfirm"
                                  value={passwordconfirm}
                                  onChange={e => setPasswordConfirm(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p className={messageColor}>{errmsg}</p>
                          </Col>
                        </Row>
                      </Container>
                    </Card.Body>
                    <Card.Footer>
                      <Row className="mt-3 mb-3">
                        {saveLoading
                          ?
                          <Col className="text-end">
                            'Saving password ... '
                          </Col>
                          :
                          <Col className="text-end">
                            <Button variant="primary" onClick={handleSubmit} type="submit">Save Changes</Button>
                          </Col>
                        }
                      </Row>
                    </Card.Footer>
                  </Form>
                </Card>

                <Link to='/' className="mb-30 mt-30 animate__animated animate__slideInUp">Let's go home <ArrowRightCircle size={25} /></Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer style={{ height: '20vh' }} />
    </>
  )
}