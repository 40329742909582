import { Button, Modal, Spinner, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useSelector } from "react-redux"
import { useState } from 'react'

export default function AddNewModal({ role, getRoles }) {

  const { user: authUser } = useSelector(x => x.auth)

  const url = process.env.REACT_APP_URL

  const [saveLoading, setSaveLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSaveLoading(true)

    try {
      const response = await fetch(`${url}/meetingrole/${role._id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authUser.token}`
        },
      })

      if (response.status === 200) {
        await response.json()
        toast.success(`Role ${role.name} successfully deleted`, { position: 'bottom-left' })
        getRoles() // Refresh the roles list
        handleCloseModal()
      } else {
        console.log(response)
        toast.error(`Failed to delete role ${role.name}`, { position: 'bottom-left' })
      }
    } catch (error) {
      console.error(error)
      toast.error(`Failed to add role ${role.name}`, { position: 'bottom-left' })
    } finally {
      setSaveLoading(false)
    }
  }

  return (

    <>
      <Dropdown.Item onClick={handleShowModal} variant="success" className='mb-1 float-end'>Delete Role</Dropdown.Item>
      <Modal show={showModal} onHide={handleCloseModal} className='text-center' data-bs-theme={'dark'} size="lg" style={{ top: '2%' }}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Modal.Title>
            Delete Role: <br /> {role.name}
          </Modal.Title>
          Are you sure you want to delete this role?
        </Modal.Body>
        <Modal.Footer>
          {saveLoading
            ?
            <>
              <Spinner animation='border' variant='danger' />
              Deleting Role ...
            </>
            :
            <>
              <Button variant="danger" type='submit' onClick={handleSubmit}>
                Delete Role
              </Button>
              <Button variant="dark" onClick={handleCloseModal}>
                Cancel
              </Button>
            </>
          }
        </Modal.Footer>
      </Modal>
    </>
  )
}