import { Button, Modal, Spinner, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useSelector } from "react-redux"
import { useState } from 'react'

export default function AddNewModal({ event, getEvents }) {

  const { user: authUser } = useSelector(x => x.auth)

  const url = process.env.REACT_APP_URL

  const [saveLoading, setSaveLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSaveLoading(true)

    try {
      const response = await fetch(`${url}/agendaEvent/${event._id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authUser.token}`
        },
      })

      if (response.status === 200) {
        await response.json()
        toast.success(`Event ${event.name} successfully deleted`, { position: 'bottom-left' })
        getEvents() // Refresh the roles list
        handleCloseModal()
      } else {
        console.log(response)
        toast.error(`Failed to delete event ${event.name}`, { position: 'bottom-left' })
      }
    } catch (error) {
      console.error(error)
      toast.error(`Failed to delete event ${event.name}`, { position: 'bottom-left' })
    } finally {
      setSaveLoading(false)
    }
  }

  return (

    <>
      <Dropdown.Item onClick={handleShowModal} variant="success" className='mb-1 float-end'>Delete Role</Dropdown.Item>
      <Modal show={showModal} onHide={handleCloseModal} className='text-center' data-bs-theme={'dark'} size="lg" style={{ top: '2%' }}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Modal.Title>
            Delete Event: <br /> {event.name}
          </Modal.Title>
          Are you sure you want to delete this Event?
        </Modal.Body>
        <Modal.Footer>
          {saveLoading
            ?
            <>
              <Spinner animation='border' variant='danger' />
              Deleting Event ...
            </>
            :
            <>
              <Button variant="danger" type='submit' onClick={handleSubmit}>
                Delete Event
              </Button>
              <Button variant="dark" onClick={handleCloseModal}>
                Cancel
              </Button>
            </>
          }
        </Modal.Footer>
      </Modal>
    </>
  )
}