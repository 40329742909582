// import Avatar from "./settings/Avatar";
import DeleteAccount from "./settings/DeleteAccount";
// import Notifications from "./settings/Notifications";
import PasswordReset from "./settings/PasswordReset";
import UserDetails from "./settings/UserDetails";
import { useContext } from "react"
import { useSelector } from "react-redux";
import { ProfileContext } from "pages/Profile";

export default function Settings() {

  const { user: authUser } = useSelector(x => x.auth)
  const { profile } = useContext(ProfileContext)

  return (
    <>
      {/* <Avatar /> */}
      <UserDetails profile={profile.profile} />
      <PasswordReset profile={profile.profile} />
      {/* <Notifications /> */}
      {
        ((authUser.user.acl === 'Officer' || authUser.user._id === profile._id) && profile.hidden !== true) &&
        <DeleteAccount profile={profile.profile} />
      }
    </>
  )
}