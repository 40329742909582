import { Row, Col, Card, Dropdown } from 'react-bootstrap'
import { useSelector } from "react-redux"

import navIcon2 from "../../assets/img/nav-icon2.svg";
import youTube from '../../assets/svg/brand-logos/icons8-youtube-logo-100.svg';

import MeetingEditModal from '../Meetings/crudModals/MeetingEditModal'
// import MeetingDeleteModal from '../Meetings/crudModals/MeetingDeleteModal'

export default function MeetingCard(meeting) {

  // const url = process.env.REACT_APP_URL
  const noBgImage = process.env.REACT_APP_NO_BG_IMAGE

  const { user: authUser } = useSelector(x => x.auth)
  const theMeeting = meeting.meeting.meeting
  const backgroundImage = (theMeeting.imageUrl === '' || theMeeting.imageUrl === 'undefined' || !theMeeting.imageUrl) ? noBgImage : theMeeting.imageUrl
  // const meetingTitle = (theMeeting.imageUrl === '' || theMeeting.imageUrl === 'undefined' || !theMeeting.imageUrl) ? theMeeting.meetingTheme : ''

  return (
    <section>
      <Row>
        <Col>
          <Card className='my-2 text-center semi-transparent text-white bg-dark' >
            <Card.Title className='my-2'>
              <Row>
                <Col style={{ height: '21px' }}></Col>
                <Col xs={2} lg={2} >
                  {(authUser && authUser.user.acl === 'Officer') &&
                    <Dropdown drop='start' data-bs-theme="dark" className='float-end'>
                      <Dropdown.Toggle variant="dark">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis-vertical" className="svg-inline--fa fa-ellipsis-vertical " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" width="6" height="16"><path fill="currentColor" d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"></path></svg>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <MeetingEditModal meeting={theMeeting} />
                        {/* <Dropdown.Divider /> */}
                        {/* <MeetingDeleteModal meeting={theMeeting} getMeeting={getMeeting} /> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                </Col>
              </Row>
            </Card.Title>
            <Card.Body className='to-left'>
              <Row>
                <Col xs={12} lg={12}>
                  <Card.Img variant="left" src={backgroundImage} alt='Meeting ' style={{ marginTop: "-42px" }} />
                  {/* <h3 className='not-found-title'>{theMeeting.meetingTheme}</h3> */}
                </Col>
              </Row>
              <Row>
                <Col className='mt-4'>
                  <h3 className='mb-4'>{theMeeting.meetingTheme}</h3>
                  <p>{theMeeting.meetingType} Meeting <span>{(theMeeting.meetingNumber !== null) && '#' + theMeeting.meetingNumber}</span></p>
                  <p>{theMeeting.meetingDate}</p>
                  <h5>{theMeeting.meetingDescription !== '' && <>{theMeeting.meetingDescription}</>} </h5>
                  <br />
                </Col>
              </Row>
              <Row className='to-left'>
                <Col className='social-icon mt-3'>
                  {(theMeeting.facebookUrl !== '' && theMeeting.facebookUrl) ? <a href={theMeeting.facebookUrl} target='_blank' rel="noreferrer"><img src={navIcon2} alt="Facebook Icon Event" /></a> : ''}
                  {(theMeeting.youtubeUrl !== '' && theMeeting.youtubeUrl) ? <a href={theMeeting.youtubeUrl} target='_blank' rel="noreferrer"><img src={youTube} alt="Youtube Icon Event" /></a> : ''}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </section>
  )
}