import { Card, Row, Col, Dropdown } from 'react-bootstrap'
import EditModal from './crudModals/EditModal'
import DeleteModal from './crudModals/DeleteModal'
// import { useSelector } from 'react-redux'

export default function RolesCard({ role, getRoles }) {
  // const { user } = useSelector(x => x.auth)
  return (
    <Col xs={12} md={12} lg={12}>
      <Card className="roles-card">
        <Card.Title><h3>{role.name}</h3></Card.Title>
        <Card.Body>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <div dangerouslySetInnerHTML={{ __html: role.description }}></div>
            </Col>
          </Row>

        </Card.Body>
        <Card.Footer>
          <Dropdown className="roles-card-dropdown" data-bs-theme={'dark'}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <EditModal role={role} getRoles={getRoles} />
              <DeleteModal role={role} getRoles={getRoles} />
            </Dropdown.Menu>
          </Dropdown>
        </Card.Footer>
      </Card>
    </Col>
  )
}