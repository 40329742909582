import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from 'react'
import { format, parse } from 'date-fns';


export default function MeetingEditModal({ meeting, getMeetings = null }) {
    const url = process.env.REACT_APP_URL
    const { user: authUser } = useSelector(x => x.auth)

    const [showEditMeetingModal, setShowEditMeetingModal] = useState(false)
    const [meetingType, setMeetingType] = useState('')
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [meetingDate, setMeetingDate] = useState('')
    const [meetingTime, setMeetingTime] = useState('')
    const [meetingAddress, setMeetingAddress] = useState('')
    const [meetingNumber, setMeetingNumber] = useState('')
    const [meetingTheme, setMeetingTheme] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [facebookUrl, setFacebookUrl] = useState('')
    const [youtubeUrl, setYoutubeUrl] = useState('')
    const [meetingDescription, setMeetingDescription] = useState('')
    const [saveLoading, setSaveLoading] = useState(false)

    const handleCloseEditMeetingModal = () => setShowEditMeetingModal(false)
    const handleShowEditMeetingModal = () => setShowEditMeetingModal(true)

    useEffect(() => {
        if (meeting) {
            setMeetingType(meeting.meetingType || '')
            const parsedDate = parse(meeting.meetingDate, 'dd/MM/yyyy', new Date());
            setSelectedDate(parsedDate || new Date());
            setMeetingDate(meeting.meetingDate || '');
            setMeetingTime(meeting.meetingTime || '');
            setMeetingAddress(meeting.meetingAddress || '');
            setMeetingNumber(meeting.meetingNumber || '');
            setMeetingTheme(meeting.meetingTheme || '');
            setImageUrl(meeting.imageUrl || '');
            setFacebookUrl(meeting.facebookUrl || '');
            setYoutubeUrl(meeting.youtubeUrl || '');
            setMeetingDescription(meeting.meetingDescription || '');
        }
    }, [meeting]);
    const handleSubmit = async (e) => {
        e.preventDefault()
        setSaveLoading(true)
        try {
            const formattedDate = format(selectedDate, 'dd/MM/yyyy');
            const data = {
                meetingType,
                meetingDate: formattedDate,
                meetingTime,
                meetingAddress,
                meetingNumber,
                meetingTheme,
                imageUrl,
                facebookUrl,
                youtubeUrl,
                meetingDescription
            }
            const response = await fetch(`${url}/meeting/${meeting._id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authUser.token
                },
                body: JSON.stringify(data)
            })
            if (response.ok) {
                toast.success(`meeting ${meetingTheme} has been updated`, { position: 'bottom-left' })
                if (getMeetings) getMeetings()
                handleCloseEditMeetingModal()
            }
            else {
                toast.error(`Failed to update the meeting ${meetingTheme}`)
            }
        }
        catch (error) {
            toast.error(`An error occurred while updating the meeting ${meetingTheme}`)
        }
        finally {
            console.log(meetingDate)
            setSaveLoading(false)
        }
    }

    return (
        <>
            <Dropdown.Item onClick={handleShowEditMeetingModal}>Edit Meeting</Dropdown.Item>
            <Modal show={showEditMeetingModal} onHide={handleCloseEditMeetingModal} className='text-center' data-bs-theme={'dark'} style={{ top: '2%' }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Meeting:<br /> {meeting.meetingTheme}
                    </Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <Form.Select
                            aria-label='Meeting Type'
                            className='mb-3'
                            name='meetingType'
                            value={meetingType}
                            onChange={e => setMeetingType(e.target.value)}
                        >
                            <option value='Regular'>Regular</option>
                            <option value='Committee'>Committee</option>
                            <option value='Contest'>Contest</option>
                            <option value='Other'>Other</option>
                        </Form.Select>
                        <InputGroup className='mb-3'>
                            <DatePicker
                                wrapperClassName='formInput'
                                showIcon
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                dateFormat="dd/MM/yyyy"
                            /> &nbsp;&nbsp;&nbsp;&nbsp;
                            <Form.Control
                                className='formInput'
                                placeholder='Time: HH:mm'
                                aria-label='Time'
                                name='meetingTime'
                                value={meetingTime}
                                onChange={e => setMeetingTime(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Location'
                                aria-label='Location'
                                name='meetingAddress'
                                value={meetingAddress}
                                onChange={e => setMeetingAddress(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Meeting Number'
                                aria-label='meetingNo'
                                name='meetingNumber'
                                value={meetingNumber}
                                onChange={e => setMeetingNumber(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Meeting Theme'
                                aria-label='meeting Theme'
                                name='meetingTheme'
                                value={meetingTheme}
                                onChange={e => setMeetingTheme(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control as="textarea"
                                className='formInput'
                                placeholder='Meeting Description'
                                aria-label='meeting Description'
                                rows={5}
                                name='meetingDescription'
                                value={meetingDescription}
                                onChange={e => setMeetingDescription(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='meeting Picture'
                                aria-label='meeting Picture'
                                name='imageUrl'
                                value={imageUrl}
                                onChange={e => setImageUrl(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='meeting facebook event url'
                                aria-label='meeting facebook event url'
                                name='facebookUrl'
                                value={facebookUrl}
                                onChange={e => setFacebookUrl(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup className='mb-3'>
                            <Form.Control
                                className='formInput'
                                placeholder='Meeting Youtube Recording'
                                aria-label='meeting Youtube Recording'
                                name='youtubeUrl'
                                value={youtubeUrl}
                                onChange={e => setYoutubeUrl(e.target.value)}
                            />
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        {saveLoading
                            ?
                            'Saving meeting ... '
                            :
                            <>
                                <Button variant="success" type='submit' onClick={handleSubmit}>
                                    Save Meeting
                                </Button>
                                <Button variant="danger" onClick={handleCloseEditMeetingModal}>
                                    Cancel
                                </Button>
                            </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}


