import "bootstrap/dist/css/bootstrap.min.css"
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import logoImg from 'assets/logos/BTMlogo.png'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './App.css';
import AppRouter from "_routes/AppRouter";

function App() {

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE
    document.querySelector("link[rel~='icon']").href = logoImg
    document.querySelector("link[rel~='apple-touch-icon']").href = logoImg

  }, []);


  return (
    <>
      <ToastContainer />
      <AppRouter />
    </>
  );
}

export default App;
