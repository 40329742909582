import { Button, Dropdown, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { useSelector } from 'react-redux'

export default function AgendaItemDeleteModal({ agendaItem, getAgenda }) {

    const { user: authUser } = useSelector(x => x.auth)

    const url = process.env.REACT_APP_URL
    const [saveLoading, setSaveLoading] = useState(false)
    const [showDeleteAgendaItemModal, setShowDeleteAgendaItemModal] = useState(false)
    const handleCloseDeleteAgendaItemModal = () => setShowDeleteAgendaItemModal(false)
    const handleShowDeleteAgendaItemModal = () => setShowDeleteAgendaItemModal(true)

    const handleSubmit = (e) => {
        e.preventDefault()
        setSaveLoading(true)
        fetch(url + '/agendaItem/' + agendaItem.agendaItemId, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authUser.token
            }
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('(' + res.status + ') We could not delete the Agenda Item ' + agendaItem.agendaEvent.name)
                }
                return res.json()
            })
            .then(resData => {
                toast.success('Your Agenda Item ' + agendaItem.agendaEvent.name + ' has been deleted', { position: 'bottom-left' })
                setSaveLoading(false)
                getAgenda()
                handleCloseDeleteAgendaItemModal()
            })
            .catch(err => {
                toast.error('' + err, { position: 'bottom-left' })
                setSaveLoading(false)
            })
    }
    return (
        <>
            <Dropdown.Item onClick={handleShowDeleteAgendaItemModal} style={{ color: '#ff848f' }}>Delete Agenda Item</Dropdown.Item >
            < Modal show={showDeleteAgendaItemModal} onHide={handleCloseDeleteAgendaItemModal} className='text-center' data-bs-theme={'dark'} style={{ top: '2%' }}>
                <Modal.Header closeButton>
                    <Modal.Title>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>Delete Agenda Item: </h2><h4>{agendaItem.agendaEvent.name}</h4>
                    Are you sure you want to delete this agenda item?
                </Modal.Body>
                <Modal.Footer>
                    {saveLoading
                        ?
                        'Deleting Agenda Item ... '
                        :
                        <>
                            <Button variant='danger' type='submit' onClick={handleSubmit}>
                                Delete Agenda Item
                            </Button>
                            <Button variant='dark' onClick={handleCloseDeleteAgendaItemModal}>
                                Cancel
                            </Button>
                        </>
                    }
                </Modal.Footer>
            </Modal >
        </>
    )
}