import { Card, Row, Col, Form, Spinner, ButtonGroup, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Avatar from "../Users/Header/Avatar";
import Profile from "../Users/Header/Profile";
import UserAdd from "./crudModals/UserAdd";

export default function ListUsers() {
  const { user: authUser } = useSelector((state) => state.auth);
  const url = process.env.REACT_APP_URL;

  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRole, setSelectedRole] = useState("");

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${url}/users/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authUser.token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`(${response.status}) We could not retrieve the users`);
      }

      const resData = await response.json();

      const roleOrder = {
        "Officer": 1,
        "Member": 2,
        "Guest": 3,
        "Past Member": 4,
        "Past Guest": 5,
        "Other Club Member": 6,
        // "Special Guest": 7,
      };

      const sortedUserList = resData
        .sort((a, b) => {
          const roleComparison = (roleOrder[a.acl] || 99) - (roleOrder[b.acl] || 99);
          if (roleComparison === 0) {
            return a.firstName.localeCompare(b.firstName);
          }
          return roleComparison;
        });

      setUserList(sortedUserList);
    } catch (error) {
      console.error("Error fetching users:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRoleFilterChange = (role) => {
    setSelectedRole(role);
  };

  const filteredUsers = userList.filter((user) => {
    const matchesSearchTerm =
      user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesRole = selectedRole ? user.acl === selectedRole : true;
    return matchesSearchTerm && matchesRole;
  });

  return (
    <section>
      {isLoading ? (
        <Spinner animation="border" variant="danger" />
      ) : (
        <>
          <Row>
            <Col>
              <h5 className="mb-5">Users List</h5>
            </Col>
            <Col className="text-end">
              <UserAdd getUsers={getUsers} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} lg={12}>
              <Form.Control
                type="text"
                placeholder="Search for a user..."
                value={searchTerm}
                onChange={handleSearchChange}
                data-bs-theme={"dark"}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12} lg={12}>
              <ButtonGroup>
                <Button variant="outline-primary" onClick={() => handleRoleFilterChange("Officer")}>
                  Officers
                </Button>
                <Button variant="outline-primary" onClick={() => handleRoleFilterChange("Member")}>
                  Members
                </Button>
                <Button variant="outline-primary" onClick={() => handleRoleFilterChange("Guest")}>
                  Guests
                </Button>
                <Button variant="outline-primary" onClick={() => handleRoleFilterChange("Past Member")}>
                  Past Members
                </Button>
                <Button variant="outline-primary" onClick={() => handleRoleFilterChange("Past Guest")}>
                  Past Guests
                </Button>
                <Button variant="outline-primary" onClick={() => handleRoleFilterChange("Other Club Member")}>
                  Other Club Member
                </Button>
                {/* <Button variant="outline-primary" onClick={() => handleRoleFilterChange("Special Guest")}>
                  Special Guest
                </Button> */}
                <Button variant="outline-secondary" onClick={() => handleRoleFilterChange("")}>
                  All
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              {filteredUsers.map((user) => (
                <Card className="mb-2" key={user._id}>
                  <Card.Body>
                    <Row>
                      <Col xs={12} lg={3}>
                        <Avatar profile={user} />
                      </Col>
                      <Col className="mt-4" xs={8} lg={9}>
                        <Row>
                          <Profile profile={user} />
                        </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))}
            </Col>
          </Row>
        </>
      )}
    </section>
  );
}