import { Button, Modal, Row, Col, Form } from 'react-bootstrap'
import { useState } from 'react'
import signinImg from '../../assets/svg/brand-logos/signin.svg'
import { useDispatch } from 'react-redux'
import { authActions } from '_store'
import { toast } from 'react-toastify'

export const SignIn = () => {

  const dispatch = useDispatch()

  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleOpenModal = () => setShowModal(true)
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const handleForgotPassword = () => {
    setShowForgotPassword(true)
  }
  const handleSignIn = () => {
    setShowForgotPassword(false)
  }

  const [authLoading, setAuthLoading] = useState(false)
  const [forgotEmail, setForgotEmail] = useState('')
  const [errmsg, setErrmsg] = useState('')
  const [saveLoading, setSaveLoading] = useState(false)
  const [messageColor, setMessageColor] = useState('text-danger')

  const url = process.env.REACT_APP_URL

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaveLoading(true);
    try {
      setErrmsg('')

      const response = await fetch(`${url}/users/reset`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: forgotEmail })
      })
      const result = await response.json();
      console.log(`${forgotEmail}`)
      const response2 = await fetch(`${process.env.REACT_APP_MAILSERVER}/html`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({
          appMailUser: process.env.REACT_APP_MAILUSER,
          appMailPass: process.env.REACT_APP_MAILPASS,
          toList: forgotEmail,
          subject: 'Brno Toastmasters password reset',
          html: `
                    <h3>Brno Toastmasters password reset</h3>
                    <p>You are receiving this because you (or someone else) have requested the reset of the password for your account.</p>
                    <p>If you did not request this, please ignore this email and your password will remain unchanged.</p>
                    <p>Please click on the following link:</p>
                    <p><a href="${window.location.protocol}//${window.location.host}/reset/${result.resetToken}">Click here</a></p>
                    <p>or copy and paste in a new tab of your web browser the following link to complete the process: ${window.location.protocol}//${window.location.host}/reset/${result.resetToken}</p>
                    <p><small style="color:red">the link is valid only one hour since the request was made</small></p>
                    `
        })
      })
      const result2 = await response2.json();
      if (result2.code === 200) {
        setMessageColor('text-success')
        setErrmsg(`${result2.status}`)
      }
      else {
        // console.log('email not sent')
        // toast.error('Something went wrong, please try again later.', { position: 'bottom-left' });
        setMessageColor('text-danger')
        setErrmsg(`Something went wrong, please try again later.<br/>${result2.error}`)
      }
    }
    catch (error) {
      // console.log(error)
      setMessageColor('text-danger')
      setErrmsg(error)
    }
    setSaveLoading(false);
  }

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')


  const login = (e) => {
    e.preventDefault()
    setAuthLoading(true)
    if (validate()) {
      dispatch(authActions.login({ email, password }))
    }
    setAuthLoading(false)
  }

  const validate = () => {
    let result = true;
    if (email === '' || email === null) {
      result = false;
      // console.log('email missing')
      setAuthLoading(false)
      toast.warning('Please enter email', { position: 'bottom-left' })
    }
    if (password === '' || password === null) {
      result = false;
      // console.log('password missing')
      setAuthLoading(false)
      toast.warning('Please enter Password', { position: 'bottom-left' })
    }
    return result;
  }



  return (
    <>
      <Button onClick={handleOpenModal} variant='Link' data-bs-theme={'dark'}>
        <img src={signinImg} alt="SignIn Icon" />
      </Button>

      <Modal show={showModal} onHide={handleCloseModal} className='text-center' centered data-bs-theme={'dark'}>

        {
          showForgotPassword ?
            (
              <Form className='form'>
                <Modal.Header closeButton onClick={handleCloseModal}>
                  <Modal.Title >Forgotten password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="mt-2 mb-3">
                    <Col>
                      <Row className="mt-3 mb-3">
                        <Col>
                          <Form.Control
                            className="formInput"
                            placeholder="email address*"
                            aria-label="email address*"
                            name="email"
                            value={forgotEmail}
                            onChange={e => setForgotEmail(e.target.value)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className={messageColor}>{errmsg}</p>
                    </Col>
                  </Row>
                  <Row className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                    <Button variant='link' onClick={handleSignIn}>Do you want to sign in?</Button>
                  </Row>

                </Modal.Body >
                <Modal.Footer>
                  {saveLoading
                    ? <Row><Col><Button variant="primary" disabled style={{ 'width': '320px' }}>Sending email...</Button></Col><Col><Button variant="danger" disabled style={{ 'width': '100px' }}>Cancel</Button></Col></Row>
                    : <Row><Col><Button variant="primary" type="submit" style={{ 'width': '320px' }} onClick={handleSubmit}>Send reset email</Button></Col><Col><Button variant="danger" onClick={handleCloseModal} style={{ 'width': '100px' }}>Cancel</Button></Col></Row>
                  }
                </Modal.Footer>
              </Form>
            )
            :
            (
              <Form className='form'>
                <Modal.Header closeButton onClick={handleCloseModal}>
                  <Modal.Title >Sign In</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='text-center mb-10'>
                  </div>
                  <Row className='g-3 mb-9'>
                    <Col>
                      <Row className="mb-8">
                        <Form.Control type="text" placeholder='Email' name='email' value={email} onChange={e => setEmail(e.target.value)} className='form-control bg-transparent' />
                      </Row>
                      <Row className='mb-10'>
                        <Form.Control type='password' placeholder='Password' name='password' value={password} onChange={e => setPassword(e.target.value)} className='form-control bg-transparent' />
                      </Row>
                      <Row className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-2'>
                        <Button variant='link' onClick={handleForgotPassword}>Forgot password?</Button>
                      </Row>
                    </Col>
                  </Row>

                </Modal.Body >
                <Modal.Footer>
                  {authLoading
                    ? <Row><Col><Button variant="primary" disabled style={{ 'width': '320px' }}>Signing In...</Button></Col><Col><Button variant="danger" disabled style={{ 'width': '100px' }}>Cancel</Button></Col></Row>
                    : <Row><Col><Button variant="primary" type="submit" style={{ 'width': '320px' }} onClick={login}>Sign In</Button></Col><Col><Button variant="danger" onClick={handleCloseModal} style={{ 'width': '100px' }}>Cancel</Button></Col></Row>
                  }
                </Modal.Footer>
              </Form>
            )
        }


      </Modal >
    </>
  )
}