import { Card, Row, Col, Container, Button } from "react-bootstrap";
import { useState, useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { ProfileContext } from "pages/Profile";
import { toast } from "react-toastify";

export default function PasswordReset() {
  const { profile } = useContext(ProfileContext)
  const { user: authUser } = useSelector(x => x.auth)

  const url = process.env.REACT_APP_URL

  const [saveLoading, setSaveLoading] = useState(false)

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setSaveLoading(true);
    try {
      const response = await fetch(`${url}/users/reset`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: profile.email })
      })
      const result = await response.json();
      const response2 = await fetch(`${process.env.REACT_APP_MAILSERVER}/html`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({
          appMailUser: process.env.REACT_APP_MAILUSER,
          appMailPass: process.env.REACT_APP_MAILPASS,
          from: 'brnotoastmasterspresident@gmail.com',
          toList: profile.email,
          subject: 'Brno Toastmasters password reset',
          html: `
              <h3>Brno Toastmasters password reset</h3>
              <p>You are receiving this because you (or someone else) have requested the reset of the password for your account.</p>
              <p>If you did not request this, please ignore this email and your password will remain unchanged.</p>
              <p>Please click on the following link:</p>
              <p><a href="${window.location.protocol}//${window.location.host}/reset/${result.resetToken}">Click here</a></p>
              <p>or copy and paste in a new tab of your web browser the following link to complete the process: ${window.location.protocol}//${window.location.host}/reset/${result.resetToken}</p>
              <p><small style="color:red">the link is valid only one hour since the request was made</small></p>
              `
        })
      })
      const result2 = await response2.json();
      if (result2.code === 200) {
        console.log('email sent')
        toast.success('Email with password reset link was sent', { position: 'bottom-left' });
      }
      else {
        console.log('email not sent')
        toast.error('Something went wrong, please try again later.', { position: 'bottom-left' });
      }
    }
    catch (error) {
      console.log(error)
      toast.error(`Something went wrong: ${error}`, { position: 'bottom-left' });
    }
    setSaveLoading(false);
  }, [profile.email, url])

  return (
    <Card data-bs-theme={'dark'} className="mt-3 mb-3">
      <Card.Header>
        <Row className="mt-3 mb-3"><Col>Sign-in Method</Col></Row>
      </Card.Header>
      <Card.Body>
        <Container>
          <Row className="mt-2 mb-3">
            <Col>
              <Row className="mt-3 mb-3">
                <Col xs={8} lg={10}>
                  <p>Email account: <span>{profile.email}</span></p>

                </Col>
                {/* <Col className="text-end">
                  <Button variant="secondary">Change email</Button>
                </Col> */}
              </Row>
              <Row className="mb-3">
                <Col xs={8} lg={8}>
                  <p>Password: <span>************</span></p>
                </Col>
                <Col className="text-end">
                  {
                    (authUser.user.acl === 'Officer' || authUser.user._id === profile._id) &&
                    (saveLoading
                      ?
                      'sending email for password reset... '
                      :
                      <Button variant="primary" onClick={handleSubmit}>Change password</Button>
                    )
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card >
  )
}