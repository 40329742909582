import { NavBar } from "components/general/NavBar"
import { Footer } from "components/general/Footer"
import RolesComponent from "components/Roles/RolesComponent"
import { Row, Col, Container } from 'react-bootstrap'


export default function Roles() {

  return (
    <>
      <NavBar pagename="Meetings" />
      <section className="meetings">
        <Container>
          <Row>
            <Col>
              <RolesComponent />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  )
}