import { Nav } from "react-bootstrap"
import { useState, useEffect, useCallback } from "react"
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from "react-redux"
// import './NavbarMenu.css' // Import custom CSS for megamenu

export default function NavbarMenu() {
  const [activeLink, setActiveLink] = useState('home')
  const location = useLocation()
  const [nextMeeting, setNextMeeting] = useState(null)
  const url = process.env.REACT_APP_URL

  const { user: authUser } = useSelector(x => x.auth)

  const onUpdateActiveLink = (value) => {
    setActiveLink(value)
  }

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1))
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location])

  const getNextMeeting = useCallback(async () => {
    try {
      const response = await fetch(`${url}/meetings/comingup`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const data = await response.json()
      setNextMeeting(data)
    } catch (e) {
      console.log(`NavbarMenu - Next Meeting error: ${e}`)
    }
  }, [url])

  useEffect(() => {
    getNextMeeting()
  }, [getNextMeeting])

  return (
    <Nav className="me-auto">
      <Nav.Item className="nav-item">
        <Nav.Link as={Link} to="/" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>
          Home
        </Nav.Link>
        {location.pathname === '/' && (
          <div className="megamenu">
            <Nav.Link as={Link} to="#welcome" className={activeLink === 'welcome' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('welcome')}>Home</Nav.Link>
            <Nav.Link as={Link} to="#nextMeeting" className={activeLink === 'nextMeeting' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('nextMeeting')}>Next Meeting</Nav.Link>
            <Nav.Link as={Link} to="#foryou" className={activeLink === 'foryou' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('foryou')}>For you</Nav.Link>
            <Nav.Link as={Link} to="#info" className={activeLink === 'info' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('info')}>Info</Nav.Link>
            <Nav.Link as={Link} to="#connect" className={activeLink === 'connect' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('connect')}>Contact</Nav.Link>
          </div>
        )}
      </Nav.Item>
      <Nav.Item className="nav-item">
        <Nav.Link className="navbar-link">
          Meetings
        </Nav.Link>
        <div className="megamenu">
          {nextMeeting && (
            <Nav.Link as={Link} to={`/meeting/${nextMeeting._id}`} className={activeLink === 'nextmeeting' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('nextmeeting')}>
              Next Meeting
            </Nav.Link>
          )}
          <Nav.Link as={Link} to="/meetings" className={activeLink === 'meetings' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('meetings')}>All Meetings</Nav.Link>
          <div className="megamenu-divider"></div>
          <Nav.Link as={Link} to="/events" className={activeLink === 'events' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('events')}>Events</Nav.Link>
          <Nav.Link as={Link} to="/roles" className={activeLink === 'roles' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('roles')}>Roles</Nav.Link>
        </div>
      </Nav.Item>
      {authUser &&
        <Nav.Item className="nav-item">
          <Nav.Link as={Link} to="/users" className={activeLink === 'users' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('users')}>
            Users
          </Nav.Link>
        </Nav.Item>
      }
    </Nav>
  )
}