import { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
// import AddRoleModal from './otherModals/AddRoleModal'
import Select from 'react-select'

export default function Assignments({ getAgenda }) {

  const { user: authUser } = useSelector(x => x.auth)
  const params = useParams()

  const url = process.env.REACT_APP_URL

  const [isLoading, setIsLoading] = useState(true)
  const [roles, setRoles] = useState([])
  const [presenters, setPresenters] = useState({ officersAndMembers: [], guests: [] })
  const [selectedRoleId, setSelectedRoleId] = useState('')
  const [selectedPresenterId, setSelectedPresenterId] = useState('')
  const [assignments, setAssignments] = useState([])

  const meetingId = params.meetingId

  const getRoles = async () => {
    const response = await fetch(`${url}/meetingRoles`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authUser.token}`
      }
    })
    if (response.status === 200) {
      const roles = await response.json()
      const roleAssignmentsResponse = await fetch(`${url}/roleAssignments/${meetingId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authUser.token}`
        }
      })
      const roleAssignments = await roleAssignmentsResponse.json()
      const assignedRoleIds = roleAssignments.map(assignment => assignment.role._id)
      const availableRoles = roles.filter(role => !assignedRoleIds.includes(role._id))
      // Sort roles alphabetically
      availableRoles.sort((a, b) => a.name.localeCompare(b.name))
      setRoles(availableRoles)
    }
    else {
      console.log(response)
    }
  }

  const getPresenters = async () => {
    const response = await fetch(`${url}/users`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authUser.token}`
      }
    })
    if (response.status === 200) {
      const presenters = await response.json()
      // Group presenters by role
      const officersAndMembers = presenters.filter(p => p.acl === 'Officer' || p.acl === 'Member').sort((a, b) => a.firstName.localeCompare(b.firstName))
      const guests = presenters.filter(p => p.acl === 'Guest').sort((a, b) => a.firstName.localeCompare(b.firstName))
      setPresenters({ officersAndMembers, guests })
    }
    else {
      console.log(response)
    }
  }

  const getRoleAssignment = async () => {
    const response = await fetch(`${url}/roleAssignments/${meetingId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${authUser.token}`
      }
    })
    if (!response.Error) {
      const roleAssignment = await response.json()
      setAssignments(roleAssignment)
      getAgenda()
    }
    else {
      console.log(response.Error)
    }
  }

  const addAssignment = async (e) => {
    e.preventDefault()
    const response = await fetch(`${url}/roleAssignment/${meetingId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authUser.token}`
      },
      body: JSON.stringify({
        role: selectedRoleId,
        user: selectedPresenterId
      })
    })
    if (response.status === 200) {
      await response.json()
      getRoleAssignment()
      getRoles()
      setSelectedRoleId('') // Clear the role selection
      setSelectedPresenterId('') // Clear the presenter selection
      toast.success('Role assignment created', { position: 'bottom-left' })
    }
    else {
      toast.error(`Role assignment failed RoleID: ${selectedRoleId}, UserID: ${selectedPresenterId}`, { position: 'bottom-left' })
    }
  }

  const removeAssignment = async (e) => {
    e.preventDefault()
    const assignmentId = e.target.name
    const response = await fetch(`${url}/roleAssignment/${assignmentId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authUser.token}`
      },
    })
    if (response.status === 200) {
      await response.json()
      getRoleAssignment()
      getRoles()
      toast.success('Role assignment removed', { position: 'bottom-left' })
    }
    else {
      console.log(response)
      toast.error('Role assignment removal failed', { position: 'bottom-left' })
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      if (authUser && authUser.user.acl === 'Officer') {
        await getRoles()
        await getPresenters()
      }
      await getRoleAssignment()
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line
  }, [authUser])

  const roleOptions = roles.map(role => ({ value: role._id, label: role.name }))
  const presenterOptions = [
    {
      label: 'Members',
      options: presenters.officersAndMembers.map(presenter => ({ value: presenter._id, label: `${presenter.firstName} ${presenter.lastName}` }))
    },
    {
      label: 'Guests',
      options: presenters.guests.map(presenter => ({ value: presenter._id, label: `${presenter.firstName} ${presenter.lastName}` }))
    }
  ]

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#343a40',
      color: '#fff',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#343a40',
      color: '#fff',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#fff',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#495057' : state.isFocused ? '#495057' : '#343a40',
      color: '#fff',
    }),
    input: (provided) => ({
      ...provided,
      color: '#fff',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#6c757d',
    }),
  }

  return (
    <Container>
      <Row className='mt-1'>
        <Col>
          {
            isLoading ? <Spinner animation='border' variant='danger' /> :
              (authUser && authUser.user.acl === 'Officer') &&
              <>
                <Row>
                  <Col className="mb-4">
                    <h3>Assignments</h3>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form data-bs-theme={'dark'}>
                      <Form.Group className='mb-3'>
                        {/* <Row className='mb-3'>
                          <Col><AddRoleModal getRoles={getRoles} /></Col>
                        </Row> */}
                        <Select
                          options={roleOptions}
                          className='mb-3 formInput'
                          name='role'
                          value={roleOptions.find(option => option.value === selectedRoleId) || ''}
                          onChange={option => setSelectedRoleId(option ? option.value : '')}
                          isClearable
                          isSearchable
                          styles={customStyles}
                          placeholder='Select a role'
                        />
                      </Form.Group>
                      <Form.Group>
                        <Select
                          options={presenterOptions}
                          className='mb-3 formInput'
                          name='presenter'
                          value={presenterOptions.flatMap(group => group.options).find(option => option.value === selectedPresenterId) || ''}
                          onChange={option => setSelectedPresenterId(option ? option.value : '')}
                          isClearable
                          isSearchable
                          styles={customStyles}
                          placeholder='Select a presenter'
                        />
                      </Form.Group>
                      <Button
                        variant="success"
                        type='submit'
                        className='assignments-button w-100'
                        onClick={addAssignment}
                      >
                        Add
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </>
          }
        </Col>
      </Row>
      <Row>
        <Col className='mt-5'>
          <>
            {
              assignments.map((assignment) => (
                <Row key={assignment._id}>&nbsp;
                  <Col><h4>{assignment.role.name}</h4><p>{assignment.user.firstName} {assignment.user.lastName}</p></Col>
                  <Col xs={2} lg={2} >{(authUser && (authUser.user.acl === 'Officer' || authUser.user.acl === 'Member')) &&
                    <Button variant="danger" className="assignments-button float-end" name={assignment._id} onClick={removeAssignment}>Remove</Button>
                  }</Col>
                </Row>
              ))
            }
          </>
        </Col>
      </Row>
    </Container >
  )
}