import { Form, Button, Modal, Spinner, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useSelector } from "react-redux"
import { useEffect, useState } from 'react'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export default function AddNewModal({ role, getRoles }) {

  const { user: authUser } = useSelector(x => x.auth)

  const url = process.env.REACT_APP_URL

  const [name, setName] = useState('')
  const [description, setDescription] = useState(EditorState.createEmpty())
  const [saveLoading, setSaveLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  useEffect(() => {
    if (role) {
      setName(role.name)
      const contentBlock = htmlToDraft(role.description)
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        const editorState = EditorState.createWithContent(contentState)
        setDescription(editorState)
      }
    }
  }, [role])
  const handleSubmit = async (e) => {
    e.preventDefault()
    setSaveLoading(true)

    const value = draftToHtml(convertToRaw(description.getCurrentContent()))
    try {
      const response = await fetch(`${url}/meetingrole/${role._id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authUser.token}`
        },
        body: JSON.stringify({
          name,
          description: value
        })
      })

      if (response.status === 200) {
        await response.json()
        toast.success('Role updated successfully', { position: 'bottom-left' })
        getRoles() // Refresh the roles list
        handleCloseModal()
      } else {
        console.log(response)
        toast.error(`Failed to update role ${role.name}`, { position: 'bottom-left' })
      }
    } catch (error) {
      console.error(error)
      toast.error(`Failed to update role ${role.name}`, { position: 'bottom-left' })
    } finally {
      setSaveLoading(false)
    }
  }
  return (
    <>
      <Dropdown.Item onClick={handleShowModal} variant="success" className='mb-1 float-end'>Edit Role</Dropdown.Item>
      <Modal show={showModal} onHide={handleCloseModal} className='text-center' data-bs-theme={'dark'} size="lg" style={{ top: '2%' }}>

        <Modal.Header closeButton>
          <Modal.Title>
            Edit Role
          </Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group className='mb-3'>
              <Form.Control
                className='formInput'
                placeholder='Role Name'
                aria-label='Role Name'
                name='role'
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Editor
                editorState={description}
                wrapperClassName="demo-wrapper"
                toolbarClassName="toolbar-class"
                editorClassName="editor-class"
                onEditorStateChange={setDescription}
                placeholder="Enter role description..."
                toolbar={{
                  options: ['inline', 'blockType', 'list', 'textAlign', 'link'],
                  inline: {
                    options: ['bold', 'italic', 'underline']
                  },
                  blockType: {
                    inDropdown: false,
                    options: ['Normal', 'H4', 'H5', 'H6']
                  },
                  fontSize: {
                    visible: false,
                    options: [8, 9, 10, 11, 12, 14, 18]
                  },
                  list: {
                    inDropdown: false,
                    options: ['unordered', 'ordered']
                  },
                  textAlign: {
                    inDropdown: false
                  },
                  link: {
                    inDropdown: false
                  },
                  embedded: {
                    inDropdown: false
                  },
                }}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {saveLoading
              ?
              <>
                <Spinner animation='border' variant='danger' />
                Updating Role ...
              </>
              :
              <>
                <Button variant="success" type='submit' onClick={handleSubmit}>
                  Update Role
                </Button>
                <Button variant="danger" onClick={handleCloseModal}>
                  Cancel
                </Button>
              </>
            }
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}