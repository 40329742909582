
import Avatar from './Header/Avatar';
import Profile from './Header/Profile';
// import Pathways from './Header/Pathways';
import './profile.css'

import { Col, Row, Card } from "react-bootstrap";


export default function Header() {

  return (
    <Card className='mb-5' >
      <Card.Body>
        <Row>
          <Col xs={12} lg={3}>
            <Avatar />
          </Col>
          <Col className='mt-4' xs={8} lg={9}>

            <Row>
              <Profile />
              {/* <Pathways /> */}
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card >
  )
}