import { NavBar } from "components/general/NavBar"
import { Footer } from "components/general/Footer"
import MeetingComponent from "components/Meeting/MeetingComponent"
import AgendaComponent from "components/Meeting/AgendaComponent"
import { Row, Col, Container, Button, Card } from 'react-bootstrap'
import Assignments from "components/Meeting/Assignments"
import TimeReport from "components/Meeting/TimeReport"
// import PollComponent from "components/Meeting/PollComponent"; // Import PollComponent
import { useEffect, useState, useCallback } from "react"
import { useParams } from 'react-router-dom'
import { fetchWrapper } from '_helpers/fetch-wrapper'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux';

// SVG Icons
const CollapseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L6.707 7l4.647 4.646a.5.5 0 0 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5.5 0 0 1 .708 0z" />
  </svg>
);

const ExpandIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l5 5a.5.5 0 0 1 0 .708l-5 5a.5.5 0 0 1-.708-.708L9.293 8 4.646 3.354a.5.5 0 0 1 0-.708z" />
  </svg>
);

export default function Meeting() {
  const params = useParams()
  const url = process.env.REACT_APP_URL
  const meetingId = params.meetingId
  const { user: authUser } = useSelector(x => x.auth);

  const [isLoading, setIsLoading] = useState(true)
  const [meeting, setMeeting] = useState([])
  const [agenda, setAgenda] = useState([])
  const [previousMeeting, setPreviousMeeting] = useState(null);
  const [nextMeeting, setNextMeeting] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isAssignmentsCollapsed, setIsAssignmentsCollapsed] = useState(true)
  // const [isPollCollapsed, setIsPollCollapsed] = useState(true)
  const [isTimeReportCollapsed, setIsTimeReportCollapsed] = useState(true)

  const isLargeScreen = useMediaQuery({ query: '(min-width: 992px)' }) // lg and above

  const getPreviousMeeting = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${url}/meetings/previous?date=${meeting.meetingDate}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (!data.Error) {
        setPreviousMeeting(data._id);
      } else {
        console.log(`An error has occurred getting the Previous Meeting`);
      }
    } catch (error) {
      console.error(`An error has occurred: ${error.message}`);
    }
    setIsLoading(false);
  }, [url, meeting.meetingDate]);


  const getNextMeeting = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${url}/meetings/next?date=${meeting.meetingDate}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (!data.Error) {
        setNextMeeting(data._id);
      } else {
        console.log(`An error has occurred getting the Next Meeting`);
      }
    } catch (error) {
      console.error(`An error has occurred: ${error.message}`);
    }
    setIsLoading(false);
  }, [url, meeting.meetingDate]);

  const getMeeting = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await fetchWrapper.get(`${url}/meeting/${meetingId}`)
      if (!response.Error) {
        setMeeting(response)
      } else {
        console.log(`An error has occurred getting the Meeting`)
      }
    } catch (error) {
      console.error(`An error has occurred: ${error.message}`)
    }
    setIsLoading(false)
  }, [url, meetingId])

  const getAgenda = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await fetchWrapper.get(`${url}/agendaItems/${meetingId}`)
      if (!response.Error) {
        setAgenda(response)
      } else {
        console.log(`An error has occurred getting the Agenda`)
      }
    } catch (error) {
      console.error(`An error has occurred: ${error.message}`)
    }
    setIsLoading(false)
  }, [url, meetingId])

  useEffect(() => {
    getAgenda()
  }, [getAgenda])

  useEffect(() => {
    getMeeting()
  }, [getMeeting])

  useEffect(() => {
    getPreviousMeeting()
  }, [getPreviousMeeting])

  useEffect(() => {
    getNextMeeting()
  }, [getNextMeeting])

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }
  const toggleAssignmentsCollapse = () => {
    setIsAssignmentsCollapsed(!isAssignmentsCollapsed)
  }
  // const togglePolls = () => {
  //   setIsPollCollapsed(!isPollCollapsed)
  // }
  const toggleTimeReportCollapse = () => {
    setIsTimeReportCollapsed(!isTimeReportCollapsed)
  }

  return (
    <>
      <NavBar pagename="Meetings" />
      <section className="meeting">
        <Container>
          <Row className="mt-3 mb-3">
            <Col>
              {previousMeeting && (
                <Button variant="success" href={`/meeting/${previousMeeting}`} className="mb-3"> <CollapseIcon /> Previous Meeting</Button>
              )}
            </Col>

            <Col className="text-end">
              {nextMeeting && (
                <Button variant="success" href={`/meeting/${nextMeeting}`} className="mb-3">Next Meeting <ExpandIcon /></Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={isCollapsed ? 'auto' : 4} style={{ maxWidth: isCollapsed ? '50px' : 'none' }}>
              {isLargeScreen && (
                <Button variant="primary" onClick={toggleCollapse} className="mb-3">
                  {isCollapsed ? <ExpandIcon /> : <>Sidebar <CollapseIcon /></>}
                </Button>
              )}
              <div style={{ display: isCollapsed ? 'none' : 'block' }}>
                <MeetingComponent meeting={meeting} getMeeting={getMeeting} isLoading={isLoading} />
                <Card>
                  <Card.Header>
                    <Button variant="primary" onClick={toggleAssignmentsCollapse} className="mb-3 mt-3">
                      Assignments {isAssignmentsCollapsed ? <ExpandIcon /> : <CollapseIcon />}
                    </Button>
                  </Card.Header>

                  <Card.Body style={{ display: isAssignmentsCollapsed ? 'none' : 'block' }}>
                    <Assignments getAgenda={getAgenda} />
                  </Card.Body>
                  <Card.Footer>
                    <small className="float-end">Assignments</small>
                  </Card.Footer>
                </Card>
                {/* <Card>
                  <Card.Header>
                    <Button variant='primary' onClick={togglePolls} className='mb-3 mt-3'>
                      Polls {isPollCollapsed ? <ExpandIcon /> : <CollapseIcon />}
                    </Button>
                  </Card.Header>
                  <Card.Body style={{ display: isPollCollapsed ? 'none' : 'block' }}>
                    <PollComponent agendaItems={agenda} />
                  </Card.Body>
                  <Card.Footer>
                    <small className='float-end'>Polls</small>
                  </Card.Footer>
                </Card> */}
                {(authUser && (authUser.user.acl === 'Officer' || authUser.user.acl === 'Member')) &&
                  <Card className="mt-3 mb-3 semi-transparent text-white bg-dark">
                    <Card.Header>
                      <Button variant="primary" onClick={toggleTimeReportCollapse} className="mb-3 mt-3">
                        Time Report {isTimeReportCollapsed ? <ExpandIcon /> : <CollapseIcon />}
                      </Button>
                    </Card.Header>
                    <Card.Body style={{ display: isTimeReportCollapsed ? 'none' : 'block' }}>
                      <TimeReport />
                    </Card.Body>
                    <Card.Footer>
                      <small className="float-end">Time Report</small>
                    </Card.Footer>
                  </Card>
                }
              </div>
            </Col>
            <Col xs={12} lg={isCollapsed ? true : 8}>
              <AgendaComponent meeting={meeting} getAgenda={getAgenda} agenda={agenda} isLoading={isLoading} />
            </Col>
          </Row>

        </Container>
      </section>
      <Footer />
    </>
  )
}