import { useEffect, useState, useCallback, useContext } from "react";
import { Card, Row, Col, Form, Container, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ProfileContext } from "pages/Profile";

export default function UserDetails() {

  const { profile, setProfile } = useContext(ProfileContext)
  const { user: authUser } = useSelector(x => x.auth)

  const url = process.env.REACT_APP_URL

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [website, setWebsite] = useState('')
  const [memberNo, setMemberNo] = useState('')
  const [acl, setAcl] = useState('')
  const [clubRoles, setClubRoles] = useState([])
  const [saveLoading, setSaveLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(function () {
    !profile.firstName ? setFirstName('') : setFirstName(profile.firstName)
    !profile.lastName ? setLastName('') : setLastName(profile.lastName)
    !profile.phone ? setPhone('') : setPhone(profile.phone)
    !profile.website ? setWebsite('') : setWebsite(profile.website)
    !profile.memberNo ? setMemberNo('') : setMemberNo(profile.memberNo)
    !profile.acl ? setAcl('') : setAcl(profile.acl)
    !profile.clubRoles ? setClubRoles('') : setClubRoles(profile.clubRoles)
  }, [profile.firstName, profile.lastName, profile.phone, profile.website, profile.memberNo, profile.acl, profile.clubRoles])

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setSaveLoading(true);
    try {
      const data = {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        website: website,
        memberNo: memberNo,
        acl: acl,
        clubRoles: clubRoles
      };
      // console.log(data);
      const response = await fetch(`${url}/users/${profile._id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authUser.token}`
        },
        body: JSON.stringify(data)
      });
      const result = await response.json();
      setProfile(result);
      toast.success('The user details have been updated', { position: 'bottom-left' });
      // console.log(result);
      setSaveLoading(false);
    } catch (error) {
      toast.error('Failed to update user details', { position: 'bottom-left' });
      console.error(error);
      setSaveLoading(false);
    }
  }, [firstName, lastName, phone, website, memberNo, acl, clubRoles, url, profile._id, authUser.token, setProfile]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === 's') {
        e.preventDefault();
        handleSubmit(e);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSubmit]);


  useEffect(() => {
    (authUser.user.acl === 'Officer' || authUser.user._id === profile._id) &&
      setIsDisabled(false)
  }, [authUser.user.acl, authUser.user._id, profile._id])

  return (
    <Card data-bs-theme={'dark'} className="mt-3 mb-3">
      <Form>
        <Card.Header>
          <Row className="mt-3 mb-3"><Col>Profile Details</Col></Row>
        </Card.Header>
        <Card.Body>
          <Container>
            <Row className="mt-2 mb-3">
              <Col>
                <Row className="mt-3 mb-3">
                  <Col xs={4} lg={2}>
                    <p>First Name<span >*</span></p>
                  </Col>
                  <Col>
                    <Form.Control
                      disabled={isDisabled}
                      className="formInput"
                      placeholder="First Name*"
                      aria-label="First Name*"
                      name="fname"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={4} lg={2}>
                    <p>Last Name<span>*</span></p>
                  </Col>
                  <Col>
                    <Form.Control
                      disabled={isDisabled}
                      className="formInput"
                      placeholder="Last Name*"
                      aria-label="Last Name*"
                      name="lname"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={4} lg={2}>
                    <p>Phone</p>
                  </Col>
                  <Col>
                    <Form.Control
                      disabled={isDisabled}
                      className="formInput"
                      placeholder="Phone"
                      aria-label="Phone"
                      name="phone"
                      value={phone}
                      onChange={e => setPhone(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={4} lg={2}>
                    <p>Website</p>
                  </Col>
                  <Col>
                    <Form.Control
                      disabled={isDisabled}
                      className="formInput"
                      placeholder="https://yourwebsite.com"
                      aria-label="Website"
                      name="website"
                      value={website}
                      onChange={e => setWebsite(e.target.value)}
                    />
                  </Col>
                </Row>
                {(authUser.user.acl === 'Officer') &&
                  <Row className="mb-3">
                    <Col xs={4} lg={2}>
                      <p>Toastmasters Membership</p>
                    </Col>
                    <Col>
                      <Form.Select
                        disabled={isDisabled}
                        aria-label='Membership type'
                        className='mb-3 formInput'
                        name='membershipType'
                        value={acl}
                        onChange={e => setAcl(e.target.value)}
                      >
                        <option value='Guest' className='formInput'>Guest</option>
                        <option value='Member' className='formInput'>Member</option>
                        <option value='Officer' className='formInput'>Officer</option>
                        <option value='Past Member' className='formInput'>Past Member</option>
                        <option value='Past Guest' className='formInput'>Past Guest</option>
                        <option value='Other Club Member' className='formInput'>Other Club Member</option>
                        {/* <option value='Special Guest' className='formInput'>Special Guest</option> */}
                      </Form.Select>
                    </Col>
                  </Row>
                }
                {(acl === 'Member' || acl === 'Officer') &&
                  <Row className="mb-3">
                    <Col xs={4} lg={2}>
                      <p>Member Number</p>
                    </Col>
                    <Col>
                      <Form.Control
                        disabled={isDisabled}
                        className="formInput"
                        placeholder="Toastmasters Org Membership Number"
                        aria-label="Member Number"
                        name="memberNo"
                        value={memberNo}
                        onChange={e => setMemberNo(e.target.value)}
                      />
                    </Col>
                  </Row>
                }
                {acl === 'Officer' && (
                  <Row className="mb-3">
                    <Col xs={4} lg={2}>
                      <p>Toastmasters Roles</p>
                    </Col>
                    <Col>
                      <Form.Select
                        disabled={isDisabled}
                        className="formInput"
                        aria-label="Role"
                        name="role"
                        value={clubRoles}
                        onChange={e => setClubRoles([...e.target.selectedOptions].map(option => option.value))}
                        multiple
                        htmlSize={8}
                      >
                        <option value="President">President</option>
                        <option value="Vice President of Education">Vice President of Education</option>
                        <option value="Vice President of Membership">Vice President of Membership</option>
                        <option value="Vice President of Public Relations">Vice President of Public Relations</option>
                        <option value="Secretary">Secretary</option>
                        <option value="Treasurer">Treasurer</option>
                        <option value="Sergeant at Arms">Sergeant at Arms</option>
                        <option value="Immediate Past President">Immediate Past President</option>
                      </Form.Select>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Container>
        </Card.Body>
        {
          (authUser.user.acl === 'Officer' || authUser.user._id === profile._id) &&
          <Card.Footer>
            <Row className="mt-3 mb-3">
              {saveLoading
                ?
                'Saving profile ... '
                :
                <Col className="text-end">
                  <Button variant="primary" onClick={handleSubmit}>Save Changes</Button><br />
                </Col>
              }
            </Row>
          </Card.Footer>
        }

      </Form>
    </Card >
  )
}