import { Row, Col, Button } from 'react-bootstrap'
import { useSelector } from "react-redux"
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import AgendaItemCard from './AgendaItemCard'
import AgendaItemAddNewModal from './crudModals/AgendaItemAddNewModal'
import { Spinner } from 'react-bootstrap'
import noSleep from 'nosleep.js'
const date = require('date-and-time');

export default function AgendaComponent({ meeting, getAgenda, agenda, isLoading }) {

  // console.log(meeting)
  // console.log(agenda)
  const { user: authUser } = useSelector(x => x.auth)
  const params = useParams()
  const meetingId = params.meetingId

  let myNoSleep = new noSleep()

  const [isNoSleep, setIsNoSleep] = useState(false)
  const [toggleText, setToggleText] = useState('Keep Screen On')
  const [toggleColor, setToggleColor] = useState('primary')

  useEffect(() => {
    if (isNoSleep) { myNoSleep.enable() } else { myNoSleep.disable(); }
    // eslint-disable-next-line
  }, [isNoSleep])

  const toggleWakeOnScreen = (e) => {
    e.preventDefault()
    setIsNoSleep(!isNoSleep)
    isNoSleep ? setToggleText('Keep Screen On') : setToggleText('Allow screen off')
    isNoSleep ? setToggleColor('primary') : setToggleColor('danger')
    if (toggleColor === 'danger') { window.location.reload(false); }
  }

  const calculateStartTime = (meetingTime, agenda) => {
    let startTime = meetingTime
    return agenda.map((agendaItem, index) => {
      if (agendaItem.position === 1) {
        let parts = meetingTime.split(':')
        let convertedDate = new Date()
        convertedDate.setHours(parts[0])
        convertedDate.setMinutes(parts[1])
        let newStartTimeDate = date.addMinutes(convertedDate, agendaItem.agendaEvent.total.split(':')[0])
        let newHours = String(newStartTimeDate.getHours())
        let newMinutes = (String(newStartTimeDate.getMinutes()) < 10) ? `0${String(newStartTimeDate.getMinutes())}` : String(newStartTimeDate.getMinutes())
        startTime = `${newHours}:${newMinutes}`
        return { ...agendaItem, startTime: meetingTime }
      }
      else {
        let originalStartTime = startTime
        let parts = startTime.split(':')
        let convertedDate = new Date()
        convertedDate.setHours(parts[0])
        convertedDate.setMinutes(parts[1])
        let newStartTimeDate = date.addMinutes(convertedDate, agendaItem.agendaEvent.total.split(':')[0])
        let newHours = String(newStartTimeDate.getHours())
        let newMinutes = (String(newStartTimeDate.getMinutes()) < 10) ? `0${String(newStartTimeDate.getMinutes())}` : String(newStartTimeDate.getMinutes())
        startTime = `${newHours}:${newMinutes}`
        return { ...agendaItem, startTime: originalStartTime }
      }
    })
  }

  const meetingTime = agenda.length > 0 ? agenda[0].meetingTime : ''
  const agendaWithStartTimes = meetingTime ? calculateStartTime(meetingTime, agenda) : []

  return (
    <section className='agenda' >
      {
        isLoading ?
          <Spinner animation='border' variant='danger' />
          :
          <>
            {
              (agenda.length === 0) ?
                <Row>
                  <Col>
                    <h2>There are no items in this agenda</h2>
                    {(authUser && authUser.user.acl === 'Officer') &&
                      <AgendaItemAddNewModal meetingId={meetingId} getAgenda={getAgenda} />
                    }
                  </Col>
                </Row>
                :
                <>
                  <Row>
                    <Col xs={12} xl={3}>
                      <Button variant={toggleColor} onClick={toggleWakeOnScreen}>{toggleText} {isNoSleep}</Button>
                    </Col>
                    <Col>
                      <h4 className='float-end'>#{meeting.meetingNumber} - {meeting.meetingTheme}</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {(authUser && authUser.user.acl === 'Officer') &&
                        <AgendaItemAddNewModal meetingId={meetingId} getAgenda={getAgenda} />
                      }
                      {
                        agendaWithStartTimes.map((agendaItem) => (
                          <div key={agendaItem.agendaItemId}>
                            <AgendaItemCard
                              agendaItem={agendaItem}
                              getAgenda={getAgenda}
                              startTime={agendaItem.startTime}
                            />
                            {(authUser && authUser.user.acl === 'Officer') &&
                              <AgendaItemAddNewModal meetingId={meetingId} getAgenda={getAgenda} prevPos={agendaItem.position} />
                            }
                          </div>
                        ))
                      }
                    </Col>
                  </Row>
                </>
            }
          </>
      }
    </section>
  )
}