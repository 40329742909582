import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Spinner, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import AddNewModal from './crudModals/AddNewModal';
import RolesCard from './RolesCard';

const RolesComponent = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { user: authUser } = useSelector(x => x.auth);
    const url = process.env.REACT_APP_URL;

    const getRoles = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${url}/meetingroles`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const data = await response.json();
            setRoles(data);
        } catch (e) {
            console.log(`Roles component - Get Roles error: ${e}`);
        }
        setIsLoading(false);
    }, [url]);

    useEffect(() => {
        getRoles();
    }, [getRoles]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredRoles = roles.filter(role =>
        role.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (isLoading) {
        return (
            <Spinner animation="border" variant="danger" />
        );
    } else {
        // Sort roles by name before mapping
        const sortedRoles = filteredRoles.sort((a, b) => a.name.localeCompare(b.name));

        return (
            <Container>
                <Row className='header mb-2'>
                    <Col xs={6} lg={6} className='title'><h3>Roles</h3></Col>
                    {(authUser && authUser.user.acl === 'Officer') &&
                        <Col xs={6} lg={6} key={'newRole'}>
                            <AddNewModal getRoles={getRoles} />
                        </Col>
                    }
                </Row>
                <Row className='mb-3'>
                    <Col xs={12} lg={12}>
                        <Form.Control
                            type="text"
                            placeholder="Search for a role..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            data-bs-theme={'dark'}
                        />
                    </Col>
                </Row>
                <Row className="events-list">
                    {sortedRoles.map(role => (
                        <Col xs={12} lg={12} key={role._id} className='mb-2'>
                            <RolesCard role={role} getRoles={getRoles} />
                        </Col>
                    ))}
                </Row>
            </Container>
        );
    }
};

export default RolesComponent;