import { useState } from "react"
import { Row, Col, Modal, Button, Image } from "react-bootstrap"
import { ArrowRightCircle } from 'react-bootstrap-icons'

// Info Icon
const InfoIcon = () => (
  <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    color: 'currentColor',
    fontSize: '28px',
    fontStyle: 'italic',
    fontWeight: 'bold',
    textDecoration: 'none'
  }}>
    i
  </div>
)

export default function Info(agendaItem) {
  const item = agendaItem.agendaItem

  const [showModal, setShowModal] = useState(false)
  const [showRoleDescription, setShowRoleDescription] = useState(false) // State to manage description visibility
  const [showEventDescription, setShowEventDescription] = useState(false)

  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)
  const toggleRoleDescription = () => setShowRoleDescription(!showRoleDescription) // Toggle description visibility
  const toggleEventDescription = () => setShowEventDescription(!showEventDescription)

  console.log(item)

  return (
    <>
      <Button variant="link" onClick={handleShowModal} style={{ padding: 0, border: 'none', background: 'none', textDecoration: 'none' }} className="me-4">
        <InfoIcon />
      </Button>
      <Modal
        centered
        show={showModal}
        onHide={handleCloseModal}
        className='text-center'
        data-bs-theme={'dark'}
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 style={{ fontSize: '48px' }}>
              <span style={{ fontSize: '24px' }}>{item.startTime}</span> {item.agendaEvent.name}
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: 'linear-gradient( #003f61 -100%, #78222f 151.58%)', position: 'relative' }} className="timer">
          <Row>
            <Col>
              <h1 style={{ fontSize: '64px', color: '#D38C50' }} onClick={toggleRoleDescription}>{item.meetingRole.name} <ArrowRightCircle size={30} /> </h1>
            </Col>
          </Row>
          <Row>
            <Col xs={1} lg={2} xl={3}></Col>
            <Col>
              <div dangerouslySetInnerHTML={{ __html: item.meetingRole.description }} style={{ display: showRoleDescription ? 'block' : 'none' }}></div>
            </Col>
            <Col xs={1} lg={2} xl={3}></Col>
          </Row>
          <Row>
            <Col>
              <h1 style={{ fontSize: '64px' }} onClick={toggleEventDescription}>{item.agendaEvent.name} <ArrowRightCircle size={30} /> </h1>
            </Col>
          </Row>
          <Row>
            <Col xs={1} lg={2} xl={3}></Col>
            <Col>
              <div dangerouslySetInnerHTML={{ __html: item.agendaEvent.description }} style={{ display: showEventDescription ? 'block' : 'none' }}></div>
            </Col>
            <Col xs={1} lg={2} xl={3}></Col>
          </Row>
          {(item.assignedUser && item.assignedUser.avatar) ?
            <Row className='avatar'>
              <Col className='hexagon'>
                <Image src={'data:image/png;base64, ' + item.assignedUser.avatar} />
              </Col>
            </Row>
            : ''}
          <Row>
            <Col>
              <h1 style={{ fontSize: '72px' }}>
                {item.assignedUser?.firstName.toUpperCase() || 'TBA'}
              </h1>
            </Col>
          </Row>
        </Modal.Body >
        <Modal.Footer>
          <Button variant='Success' onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  )
}