import { Navbar, Nav, Container, Button, Modal } from "react-bootstrap"
import QRCode from 'react-qr-code'
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Link } from 'react-router-dom'
import NavbarMenu from "../FrontPage/NavbarMenu"
import { SignIn } from "./Signin";
import { Signout } from "./Signout";

export const NavBar = () => {

	const { user: authUser } = useSelector(x => x.auth)

	const [showShareQr, setShowShareQr] = useState(false)
	const [scrolled, setScrolled] = useState(false);
	const handleCloseShareQr = () => setShowShareQr(false)
	const handleShowShareQr = () => setShowShareQr(true)

	// const DynamicNavMenu = lazy(() => import(`../${pagename.pagename}/NavbarMenu`))

	useEffect(() => {
		const onScroll = () => {
			if (window.scrollY > 50) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		}
		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [])

	return (
		<>
			<Navbar expand="md" className={scrolled ? "scrolled" : ""} >
				<Container>
					<Navbar.Brand >
						<Link to='/' className="navbar-link">
							<div>
								<small>Public Speaking Club</small>
								<h4>BRNO TOASTMASTERS</h4>
							</div>
						</Link>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav">
						<span className="navbar-toggler-icon"></span>
					</Navbar.Toggle>
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ms-auto">
							{/* {pagename && <Suspense fallback={<div>Loading...</div>}><DynamicNavMenu /></Suspense>} */}
							<NavbarMenu />
						</Nav>
						<span className="navbar-text">
							<Button onClick={handleShowShareQr} variant="Link"><QRCode
								title=" QR Code"
								value={window.location.href}
								size={30}
							/></Button>
							<Modal show={showShareQr} onHide={handleCloseShareQr} className='text-center' centered data-bs-theme={'light'}>
								<Modal.Header closeButton onClick={handleCloseShareQr}>
									<Modal.Title style={{ color: 'black' }}>Share our page with QR to your friends!</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<QRCode
										title=" QR Code"
										value={window.location.href}
										size={250}
									/>
								</Modal.Body>
								<Modal.Footer>
									<Button variant="secondary" onClick={handleCloseShareQr}>
										Close
									</Button>
								</Modal.Footer>
							</Modal>
							{!authUser ? <SignIn /> : <Signout />}
						</span>
					</Navbar.Collapse>
				</Container>
			</Navbar >
		</>
	)
}