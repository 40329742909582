import { useSelector } from "react-redux"
import { Card, Row, Col, Button, Dropdown } from 'react-bootstrap'
import AgendaItemEditModal from './crudModals/AgendaItemEditModal'
import AgendaItemDeleteModal from './crudModals/AgendaItemDeleteModal'
import Timer from './otherModals/Timer'
import Info from './otherModals/Info'

export default function AgendaItemCard({ agendaItem, getAgenda, startTime }) {

  const item = agendaItem
  const { user: authUser } = useSelector(x => x.auth)


  return (
    <section className='agendaItemCard'>
      <Row>
        <Col>
          <Card className='my-2 semi-transparent text-white bg-dark'>
            <Card.Header>
              <Row>
                <Col xs={6}>
                  <>
                    <h4><span>{startTime}</span> - {item.agendaEvent.name} { }</h4>
                    <h5>
                      {!item.assignedUser?.firstName ?
                        "TBA" :
                        `${item.assignedUser.firstName} ${item.assignedUser.lastName}`
                      }
                      <span> ({item.meetingRole.name})</span></h5>
                  </>
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                  {!authUser && <Info agendaItem={agendaItem} />}
                  {authUser &&
                    <>
                      <Timer agendaItem={item} className='me-3' />
                      <Dropdown drop='start' data-bs-theme="dark" className='float-end'>
                        <Dropdown.Toggle variant="dark">
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis-vertical" className="svg-inline--fa fa-ellipsis-vertical " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512" width="6" height="16"><path fill="currentColor" d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"></path></svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {(authUser.user.acl === 'Officer') &&
                            <>
                              <AgendaItemEditModal agendaItem={item} getAgenda={getAgenda} />
                              <Dropdown.Divider />
                              <AgendaItemDeleteModal agendaItem={item} getAgenda={getAgenda} />
                            </>
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  }
                </Col>
              </Row>
            </Card.Header>

            {/* <Card.Body className='text-center' dangerouslySetInnerHTML={{ __html: item.agendaEvent.description }}>
            </Card.Body> */}

            <Card.Footer>
              <>
                {(item.agendaEvent.red) && <Col><small>
                  {(item.agendaEvent.green !== "") && <Button variant='success' className='timeButton'>{item.agendaEvent.green}</Button>}
                  {(item.agendaEvent.amber !== "") && <Button variant='warning' className='text-black timeButton'>{item.agendaEvent.amber}</Button>}
                  {(item.agendaEvent.red !== "") && <Button variant='danger' className='timeButton'>{item.agendaEvent.red}</Button>}
                </small></Col>}
              </>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </section>
  )
}