import { Form, Button, Modal, Spinner, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useSelector } from "react-redux"
import { useState } from 'react'
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';


export default function AddNewModal({ getEvents }) {

  const { user: authUser } = useSelector(x => x.auth)

  const url = process.env.REACT_APP_URL

  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState(EditorState.createEmpty())
  const [subdescription, setSubdescription] = useState(EditorState.createEmpty())
  const [green, setGreen] = useState('')
  const [amber, setAmber] = useState('')
  const [red, setRed] = useState('')
  const [total, setTotal] = useState('')

  const [saveLoading, setSaveLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const handleCloseModal = () => setShowModal(false)
  const handleShowModal = () => setShowModal(true)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSaveLoading(true)

    const descriptionHtml = draftToHtml(convertToRaw(description.getCurrentContent()))
    const subDescriptionHTML = draftToHtml(convertToRaw(subdescription.getCurrentContent()))

    try {
      const response = await fetch(`${url}/agendaEvent`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authUser.token}`
        },
        body: JSON.stringify({
          name,
          title,
          description: descriptionHtml,
          subdescription: subDescriptionHTML,
          green,
          amber,
          red,
          total
        })
      })

      if (response.status === 201) {
        await response.json()
        toast.success('Event added successfully', { position: 'bottom-left' })
        getEvents() // Refresh the event list
        handleCloseModal()
      } else {
        console.log(response)
        toast.error('Failed to add Event', { position: 'bottom-left' })
      }
    } catch (error) {
      console.error(error)
      toast.error('Failed to add Event', { position: 'bottom-left' })
    } finally {
      setSaveLoading(false)
    }
  }

  return (

    <>
      <Button onClick={handleShowModal} variant="success" className='mb-1 float-end'>Add Event</Button>
      <Modal show={showModal} onHide={handleCloseModal} className='text-center' data-bs-theme={'dark'} size="lg" style={{ top: '2%' }}>

        <Modal.Header closeButton>
          <Modal.Title>
            Add Event
          </Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group className='mb-3'>
              <Form.Control
                placeholder='Event Name*'
                className='formInput'
                aria-label='Event Name*'
                name='role'
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Control
                placeholder='Event Title'
                className='formInput'
                aria-label='Event Tame'
                name='Title'
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Editor
                editorState={description}
                wrapperClassName="demo-wrapper"
                toolbarClassName="toolbar-class"
                editorClassName="editor-class"
                onEditorStateChange={setDescription}
                placeholder="Enter role description..."
                toolbar={{
                  options: ['inline', 'blockType', 'list', 'textAlign', 'link'],
                  inline: {
                    options: ['bold', 'italic', 'underline']
                  },
                  blockType: {
                    inDropdown: false,
                    options: ['Normal', 'H4', 'H5', 'H6']
                  },
                  fontSize: {
                    visible: false,
                    options: [8, 9, 10, 11, 12, 14, 18]
                  },
                  list: {
                    inDropdown: false,
                    options: ['unordered', 'ordered']
                  },
                  textAlign: {
                    inDropdown: false
                  },
                  link: {
                    inDropdown: false
                  },
                  embedded: {
                    inDropdown: false
                  },
                }}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Editor
                editorState={subdescription}
                wrapperClassName="demo-wrapper"
                toolbarClassName="toolbar-class"
                editorClassName="editor-class"
                onEditorStateChange={setSubdescription}
                placeholder="Enter role extra description..."
                toolbar={{
                  options: ['inline', 'blockType', 'list', 'textAlign', 'link'],
                  inline: {
                    options: ['bold', 'italic', 'underline']
                  },
                  blockType: {
                    inDropdown: false,
                    options: ['Normal', 'H4', 'H5', 'H6']
                  },
                  fontSize: {
                    visible: false,
                    options: [8, 9, 10, 11, 12, 14, 18]
                  },
                  list: {
                    inDropdown: false,
                    options: ['unordered', 'ordered']
                  },
                  textAlign: {
                    inDropdown: false
                  },
                  link: {
                    inDropdown: false
                  },
                  embedded: {
                    inDropdown: false
                  },
                }}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Time Limits</Form.Label>
              <Row>
                <Col>
                  <Form.Select
                    aria-label='Green'
                    className='mb-3'
                    name='Green'
                    style={{ backgroundColor: '#146c43', color: '#ffffff' }}
                    value={green}
                    onChange={e => setGreen(e.target.value)}
                  >
                    <option value=''>Select Green</option>
                    <option value='0:00'>0:00</option>
                    <option value='0:30'>0:30</option>
                    <option value='1:00'>1:00</option>
                    <option value='1:30'>1:30</option>
                    <option value='2:00'>2:00</option>
                    <option value='2:30'>2:30</option>
                    <option value='3:00'>3:00</option>
                    <option value='3:30'>3:30</option>
                    <option value='4:00'>4:00</option>
                    <option value='4:30'>4:30</option>
                    <option value='5:00'>5:00</option>
                    <option value='5:30'>5:30</option>
                    <option value='6:00'>6:00</option>
                    <option value='6:30'>6:30</option>
                    <option value='7:00'>7:00</option>
                    <option value='7:30'>7:30</option>
                    <option value='8:00'>8:00</option>
                    <option value='8:30'>8:30</option>
                    <option value='9:00'>9:00</option>
                    <option value='9:30'>9:30</option>
                    <option value='10:00'>10:00</option>
                    <option value='10:30'>10:30</option>
                    <option value='11:00'>11:00</option>
                    <option value='11:30'>11:30</option>
                    <option value='12:00'>12:00</option>
                    <option value='12:30'>12:30</option>
                    <option value='13:00'>13:00</option>
                    <option value='13:30'>13:30</option>
                    <option value='14:00'>14:00</option>
                    <option value='14:30'>14:30</option>
                    <option value='15:00'>15:00</option>
                    <option value='15:30'>15:30</option>
                    <option value='16:00'>16:00</option>
                    <option value='16:30'>16:30</option>
                    <option value='17:00'>17:00</option>
                    <option value='17:30'>17:30</option>
                    <option value='18:00'>18:00</option>
                    <option value='18:30'>18:30</option>
                    <option value='19:00'>19:00</option>
                    <option value='19:30'>19:30</option>
                    <option value='20:00'>20:00</option>
                    <option value='20:30'>20:30</option>
                    <option value='21:00'>21:00</option>
                    <option value='21:30'>21:30</option>
                    <option value='22:00'>22:00</option>
                    <option value='22:30'>22:30</option>
                    <option value='23:00'>23:00</option>
                    <option value='23:30'>23:30</option>
                    <option value='24:00'>24:00</option>
                    <option value='24:30'>24:30</option>
                    <option value='25:00'>25:00</option>
                    <option value='25:30'>25:30</option>
                    <option value='26:00'>26:00</option>
                    <option value='26:30'>26:30</option>
                    <option value='27:00'>27:00</option>
                    <option value='27:30'>27:30</option>
                    <option value='28:00'>28:00</option>
                    <option value='28:30'>28:30</option>
                    <option value='29:00'>29:00</option>
                    <option value='29:30'>29:30</option>
                    <option value='30:00'>30:00</option>
                    <option value='30:30'>30:30</option>
                    <option value='31:00'>31:00</option>
                    <option value='31:30'>31:30</option>
                    <option value='32:00'>32:00</option>
                    <option value='32:30'>32:30</option>
                    <option value='33:00'>33:00</option>
                    <option value='33:30'>33:30</option>
                    <option value='34:00'>34:00</option>
                    <option value='34:30'>34:30</option>
                    <option value='35:00'>35:00</option>
                    <option value='35:30'>35:30</option>
                    <option value='36:00'>36:00</option>
                    <option value='36:30'>36:30</option>
                    <option value='37:00'>37:00</option>
                    <option value='37:30'>37:30</option>
                    <option value='38:00'>38:00</option>
                    <option value='38:30'>38:30</option>
                    <option value='39:00'>39:00</option>
                    <option value='39:30'>39:30</option>
                    <option value='40:00'>40:00</option>
                    <option value='40:30'>40:30</option>
                    <option value='41:00'>41:00</option>
                    <option value='41:30'>41:30</option>
                    <option value='42:00'>42:00</option>
                    <option value='42:30'>42:30</option>
                    <option value='43:00'>43:00</option>
                    <option value='43:30'>43:30</option>
                    <option value='44:00'>44:00</option>
                    <option value='44:30'>44:30</option>
                    <option value='45:00'>45:00</option>
                    <option value='45:30'>45:30</option>
                    <option value='46:00'>46:00</option>
                    <option value='46:30'>46:30</option>
                    <option value='47:00'>47:00</option>
                    <option value='47:30'>47:30</option>
                    <option value='48:00'>48:00</option>
                    <option value='48:30'>48:30</option>
                    <option value='49:00'>49:00</option>
                    <option value='49:30'>49:30</option>
                    <option value='50:00'>50:00</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select
                    aria-label='Amber'
                    className='mb-3'
                    name='Amber'
                    style={{ backgroundColor: '#ffc107', color: '#000000' }}
                    value={amber}
                    onChange={e => setAmber(e.target.value)}
                  >
                    <option value=''>Select Amber</option>
                    <option value='0:00'>0:00</option>
                    <option value='0:30'>0:30</option>
                    <option value='1:00'>1:00</option>
                    <option value='1:30'>1:30</option>
                    <option value='2:00'>2:00</option>
                    <option value='2:30'>2:30</option>
                    <option value='3:00'>3:00</option>
                    <option value='3:30'>3:30</option>
                    <option value='4:00'>4:00</option>
                    <option value='4:30'>4:30</option>
                    <option value='5:00'>5:00</option>
                    <option value='5:30'>5:30</option>
                    <option value='6:00'>6:00</option>
                    <option value='6:30'>6:30</option>
                    <option value='7:00'>7:00</option>
                    <option value='7:30'>7:30</option>
                    <option value='8:00'>8:00</option>
                    <option value='8:30'>8:30</option>
                    <option value='9:00'>9:00</option>
                    <option value='9:30'>9:30</option>
                    <option value='10:00'>10:00</option>
                    <option value='10:30'>10:30</option>
                    <option value='11:00'>11:00</option>
                    <option value='11:30'>11:30</option>
                    <option value='12:00'>12:00</option>
                    <option value='12:30'>12:30</option>
                    <option value='13:00'>13:00</option>
                    <option value='13:30'>13:30</option>
                    <option value='14:00'>14:00</option>
                    <option value='14:30'>14:30</option>
                    <option value='15:00'>15:00</option>
                    <option value='15:30'>15:30</option>
                    <option value='16:00'>16:00</option>
                    <option value='16:30'>16:30</option>
                    <option value='17:00'>17:00</option>
                    <option value='17:30'>17:30</option>
                    <option value='18:00'>18:00</option>
                    <option value='18:30'>18:30</option>
                    <option value='19:00'>19:00</option>
                    <option value='19:30'>19:30</option>
                    <option value='20:00'>20:00</option>
                    <option value='20:30'>20:30</option>
                    <option value='21:00'>21:00</option>
                    <option value='21:30'>21:30</option>
                    <option value='22:00'>22:00</option>
                    <option value='22:30'>22:30</option>
                    <option value='23:00'>23:00</option>
                    <option value='23:30'>23:30</option>
                    <option value='24:00'>24:00</option>
                    <option value='24:30'>24:30</option>
                    <option value='25:00'>25:00</option>
                    <option value='25:30'>25:30</option>
                    <option value='26:00'>26:00</option>
                    <option value='26:30'>26:30</option>
                    <option value='27:00'>27:00</option>
                    <option value='27:30'>27:30</option>
                    <option value='28:00'>28:00</option>
                    <option value='28:30'>28:30</option>
                    <option value='29:00'>29:00</option>
                    <option value='29:30'>29:30</option>
                    <option value='30:00'>30:00</option>
                    <option value='30:30'>30:30</option>
                    <option value='31:00'>31:00</option>
                    <option value='31:30'>31:30</option>
                    <option value='32:00'>32:00</option>
                    <option value='32:30'>32:30</option>
                    <option value='33:00'>33:00</option>
                    <option value='33:30'>33:30</option>
                    <option value='34:00'>34:00</option>
                    <option value='34:30'>34:30</option>
                    <option value='35:00'>35:00</option>
                    <option value='35:30'>35:30</option>
                    <option value='36:00'>36:00</option>
                    <option value='36:30'>36:30</option>
                    <option value='37:00'>37:00</option>
                    <option value='37:30'>37:30</option>
                    <option value='38:00'>38:00</option>
                    <option value='38:30'>38:30</option>
                    <option value='39:00'>39:00</option>
                    <option value='39:30'>39:30</option>
                    <option value='40:00'>40:00</option>
                    <option value='40:30'>40:30</option>
                    <option value='41:00'>41:00</option>
                    <option value='41:30'>41:30</option>
                    <option value='42:00'>42:00</option>
                    <option value='42:30'>42:30</option>
                    <option value='43:00'>43:00</option>
                    <option value='43:30'>43:30</option>
                    <option value='44:00'>44:00</option>
                    <option value='44:30'>44:30</option>
                    <option value='45:00'>45:00</option>
                    <option value='45:30'>45:30</option>
                    <option value='46:00'>46:00</option>
                    <option value='46:30'>46:30</option>
                    <option value='47:00'>47:00</option>
                    <option value='47:30'>47:30</option>
                    <option value='48:00'>48:00</option>
                    <option value='48:30'>48:30</option>
                    <option value='49:00'>49:00</option>
                    <option value='49:30'>49:30</option>
                    <option value='50:00'>50:00</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select
                    aria-label='Red*'
                    className='mb-3'
                    name='Red*'
                    style={{ backgroundColor: '#dc3545', color: '#000000' }}
                    value={red}
                    onChange={e => setRed(e.target.value)}
                  >
                    <option value=''>Select Red *</option>
                    <option value='0:00'>0:00</option>
                    <option value='0:30'>0:30</option>
                    <option value='1:00'>1:00</option>
                    <option value='1:30'>1:30</option>
                    <option value='2:00'>2:00</option>
                    <option value='2:30'>2:30</option>
                    <option value='3:00'>3:00</option>
                    <option value='3:30'>3:30</option>
                    <option value='4:00'>4:00</option>
                    <option value='4:30'>4:30</option>
                    <option value='5:00'>5:00</option>
                    <option value='5:30'>5:30</option>
                    <option value='6:00'>6:00</option>
                    <option value='6:30'>6:30</option>
                    <option value='7:00'>7:00</option>
                    <option value='7:30'>7:30</option>
                    <option value='8:00'>8:00</option>
                    <option value='8:30'>8:30</option>
                    <option value='9:00'>9:00</option>
                    <option value='9:30'>9:30</option>
                    <option value='10:00'>10:00</option>
                    <option value='10:30'>10:30</option>
                    <option value='11:00'>11:00</option>
                    <option value='11:30'>11:30</option>
                    <option value='12:00'>12:00</option>
                    <option value='12:30'>12:30</option>
                    <option value='13:00'>13:00</option>
                    <option value='13:30'>13:30</option>
                    <option value='14:00'>14:00</option>
                    <option value='14:30'>14:30</option>
                    <option value='15:00'>15:00</option>
                    <option value='15:30'>15:30</option>
                    <option value='16:00'>16:00</option>
                    <option value='16:30'>16:30</option>
                    <option value='17:00'>17:00</option>
                    <option value='17:30'>17:30</option>
                    <option value='18:00'>18:00</option>
                    <option value='18:30'>18:30</option>
                    <option value='19:00'>19:00</option>
                    <option value='19:30'>19:30</option>
                    <option value='20:00'>20:00</option>
                    <option value='20:30'>20:30</option>
                    <option value='21:00'>21:00</option>
                    <option value='21:30'>21:30</option>
                    <option value='22:00'>22:00</option>
                    <option value='22:30'>22:30</option>
                    <option value='23:00'>23:00</option>
                    <option value='23:30'>23:30</option>
                    <option value='24:00'>24:00</option>
                    <option value='24:30'>24:30</option>
                    <option value='25:00'>25:00</option>
                    <option value='25:30'>25:30</option>
                    <option value='26:00'>26:00</option>
                    <option value='26:30'>26:30</option>
                    <option value='27:00'>27:00</option>
                    <option value='27:30'>27:30</option>
                    <option value='28:00'>28:00</option>
                    <option value='28:30'>28:30</option>
                    <option value='29:00'>29:00</option>
                    <option value='29:30'>29:30</option>
                    <option value='30:00'>30:00</option>
                    <option value='30:30'>30:30</option>
                    <option value='31:00'>31:00</option>
                    <option value='31:30'>31:30</option>
                    <option value='32:00'>32:00</option>
                    <option value='32:30'>32:30</option>
                    <option value='33:00'>33:00</option>
                    <option value='33:30'>33:30</option>
                    <option value='34:00'>34:00</option>
                    <option value='34:30'>34:30</option>
                    <option value='35:00'>35:00</option>
                    <option value='35:30'>35:30</option>
                    <option value='36:00'>36:00</option>
                    <option value='36:30'>36:30</option>
                    <option value='37:00'>37:00</option>
                    <option value='37:30'>37:30</option>
                    <option value='38:00'>38:00</option>
                    <option value='38:30'>38:30</option>
                    <option value='39:00'>39:00</option>
                    <option value='39:30'>39:30</option>
                    <option value='40:00'>40:00</option>
                    <option value='40:30'>40:30</option>
                    <option value='41:00'>41:00</option>
                    <option value='41:30'>41:30</option>
                    <option value='42:00'>42:00</option>
                    <option value='42:30'>42:30</option>
                    <option value='43:00'>43:00</option>
                    <option value='43:30'>43:30</option>
                    <option value='44:00'>44:00</option>
                    <option value='44:30'>44:30</option>
                    <option value='45:00'>45:00</option>
                    <option value='45:30'>45:30</option>
                    <option value='46:00'>46:00</option>
                    <option value='46:30'>46:30</option>
                    <option value='47:00'>47:00</option>
                    <option value='47:30'>47:30</option>
                    <option value='48:00'>48:00</option>
                    <option value='48:30'>48:30</option>
                    <option value='49:00'>49:00</option>
                    <option value='49:30'>49:30</option>
                    <option value='50:00'>50:00</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select
                    aria-label='Total *'
                    className='mb-3'
                    name='Total *'
                    style={{ backgroundColor: '#3498db', color: '#000000' }}
                    value={total}
                    onChange={e => setTotal(e.target.value)}
                  >
                    <option value=''>Select Time *</option>
                    <option value='0:00'>0:00</option>
                    <option value='0:30'>0:30</option>
                    <option value='1:00'>1:00</option>
                    <option value='1:30'>1:30</option>
                    <option value='2:00'>2:00</option>
                    <option value='2:30'>2:30</option>
                    <option value='3:00'>3:00</option>
                    <option value='3:30'>3:30</option>
                    <option value='4:00'>4:00</option>
                    <option value='4:30'>4:30</option>
                    <option value='5:00'>5:00</option>
                    <option value='5:30'>5:30</option>
                    <option value='6:00'>6:00</option>
                    <option value='6:30'>6:30</option>
                    <option value='7:00'>7:00</option>
                    <option value='7:30'>7:30</option>
                    <option value='8:00'>8:00</option>
                    <option value='8:30'>8:30</option>
                    <option value='9:00'>9:00</option>
                    <option value='9:30'>9:30</option>
                    <option value='10:00'>10:00</option>
                    <option value='10:30'>10:30</option>
                    <option value='11:00'>11:00</option>
                    <option value='11:30'>11:30</option>
                    <option value='12:00'>12:00</option>
                    <option value='12:30'>12:30</option>
                    <option value='13:00'>13:00</option>
                    <option value='13:30'>13:30</option>
                    <option value='14:00'>14:00</option>
                    <option value='14:30'>14:30</option>
                    <option value='15:00'>15:00</option>
                    <option value='15:30'>15:30</option>
                    <option value='16:00'>16:00</option>
                    <option value='16:30'>16:30</option>
                    <option value='17:00'>17:00</option>
                    <option value='17:30'>17:30</option>
                    <option value='18:00'>18:00</option>
                    <option value='18:30'>18:30</option>
                    <option value='19:00'>19:00</option>
                    <option value='19:30'>19:30</option>
                    <option value='20:00'>20:00</option>
                    <option value='20:30'>20:30</option>
                    <option value='21:00'>21:00</option>
                    <option value='21:30'>21:30</option>
                    <option value='22:00'>22:00</option>
                    <option value='22:30'>22:30</option>
                    <option value='23:00'>23:00</option>
                    <option value='23:30'>23:30</option>
                    <option value='24:00'>24:00</option>
                    <option value='24:30'>24:30</option>
                    <option value='25:00'>25:00</option>
                    <option value='25:30'>25:30</option>
                    <option value='26:00'>26:00</option>
                    <option value='26:30'>26:30</option>
                    <option value='27:00'>27:00</option>
                    <option value='27:30'>27:30</option>
                    <option value='28:00'>28:00</option>
                    <option value='28:30'>28:30</option>
                    <option value='29:00'>29:00</option>
                    <option value='29:30'>29:30</option>
                    <option value='30:00'>30:00</option>
                    <option value='30:30'>30:30</option>
                    <option value='31:00'>31:00</option>
                    <option value='31:30'>31:30</option>
                    <option value='32:00'>32:00</option>
                    <option value='32:30'>32:30</option>
                    <option value='33:00'>33:00</option>
                    <option value='33:30'>33:30</option>
                    <option value='34:00'>34:00</option>
                    <option value='34:30'>34:30</option>
                    <option value='35:00'>35:00</option>
                    <option value='35:30'>35:30</option>
                    <option value='36:00'>36:00</option>
                    <option value='36:30'>36:30</option>
                    <option value='37:00'>37:00</option>
                    <option value='37:30'>37:30</option>
                    <option value='38:00'>38:00</option>
                    <option value='38:30'>38:30</option>
                    <option value='39:00'>39:00</option>
                    <option value='39:30'>39:30</option>
                    <option value='40:00'>40:00</option>
                    <option value='40:30'>40:30</option>
                    <option value='41:00'>41:00</option>
                    <option value='41:30'>41:30</option>
                    <option value='42:00'>42:00</option>
                    <option value='42:30'>42:30</option>
                    <option value='43:00'>43:00</option>
                    <option value='43:30'>43:30</option>
                    <option value='44:00'>44:00</option>
                    <option value='44:30'>44:30</option>
                    <option value='45:00'>45:00</option>
                    <option value='45:30'>45:30</option>
                    <option value='46:00'>46:00</option>
                    <option value='46:30'>46:30</option>
                    <option value='47:00'>47:00</option>
                    <option value='47:30'>47:30</option>
                    <option value='48:00'>48:00</option>
                    <option value='48:30'>48:30</option>
                    <option value='49:00'>49:00</option>
                    <option value='49:30'>49:30</option>
                    <option value='50:00'>50:00</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    If green is selected, you must select amber too.
                  </p>
                  <p>
                    * mandatory field
                  </p>
                </Col>
              </Row>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {saveLoading
              ?
              <>
                <Spinner animation='border' variant='danger' />
                Adding Event ...
              </>
              :
              <>
                <Button variant="success" type='submit' onClick={handleSubmit}>
                  Add Event
                </Button>
                <Button variant="danger" onClick={handleCloseModal}>
                  Cancel
                </Button>
              </>
            }
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}