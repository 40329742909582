import { Col, Container, Row, Spinner, Button } from "react-bootstrap";
// import { useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';

export default function TimeReport() {
  // const { user: authUser } = useSelector(x => x.auth);
  const url = process.env.REACT_APP_URL;
  const meetingId = useParams().meetingId;
  const [isLoading, setIsLoading] = useState(false);
  const [timers, setTimers] = useState([]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const fetchTimers = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${url}/timers/${meetingId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (response.ok) {
        const data = await response.json();
        // console.log(data)
        setTimers(data);
      } else {
        console.error('Failed to fetch timers');
      }
    } catch (error) {
      console.error('Error fetching timers:', error);
    }
    setIsLoading(false);
  }, [url, meetingId])

  useEffect(() => {
    fetchTimers();
    // console.log(timers)
  }, [fetchTimers]);


  const getBackgroundColor = (timer) => {
    if (parseInt(timer.time) > convertToSeconds(timer.agendaItem.agendaEvent.red) + 30) return 'black'
    else if (parseInt(timer.time) >= convertToSeconds(timer.agendaItem.agendaEvent.red)) return '#dc3545'
    else if (parseInt(timer.time) >= convertToSeconds(timer.agendaItem.agendaEvent.amber)) return '#ffc107'
    else if (parseInt(timer.time) >= convertToSeconds(timer.agendaItem.agendaEvent.green)) return '#146c43'
    return 'black';
  };

  const getForegroundColor = (timer) => {
    if (parseInt(timer.time) > convertToSeconds(timer.agendaItem.agendaEvent.red) + 30) return '#ffffff'
    else if (parseInt(timer.time) >= convertToSeconds(timer.agendaItem.agendaEvent.red)) return '#ffffff'
    else if (parseInt(timer.time) >= convertToSeconds(timer.agendaItem.agendaEvent.amber)) return '#000000'
    else if (parseInt(timer.time) >= convertToSeconds(timer.agendaItem.agendaEvent.green)) return '#ffffff'
    return 'ffffff';
  };

  const convertToSeconds = (timeString) => {
    const [minutes, seconds] = timeString.split(':').map(Number)
    return minutes * 60 + seconds
  }

  return (
    <Container>
      <Row>
        <Col>
          {isLoading ? <Spinner animation='border' variant='danger' /> :
            <>
              <Row>
                <Col className="mb-4">
                  <h3>Time Report</h3>
                </Col>
                <Col className="mb-4" xs="auto">
                  <Button onClick={fetchTimers} variant="primary">Refresh</Button>
                </Col>
              </Row>
              <Row key='001'>
                <Col>
                  {timers.length > 0 ? (
                    timers
                      .sort((a, b) => a.agendaItem.position - b.agendaItem.position)
                      .map(timer => (
                        <Row key={timer._id} style={{ backgroundColor: getBackgroundColor(timer), color: getForegroundColor(timer) }} className="mb-2">
                          <Col className="mt-2 mb2">
                            <Row>
                              <Col className="mt-2">{`${timer.agendaItem.agendaEvent.name}`}</Col>
                            </Row>
                            <Row>
                              <Col className="mb-2">{`${timer.name} `}</Col>
                            </Row>
                          </Col>
                          <Col xs={2} className="mt-4 mb-4">
                            {formatTime(timer.time)}
                          </Col>
                        </Row>
                      ))
                  ) : (
                    <h4>No time recorded</h4>
                  )}
                </Col>
              </Row>
            </>
          }
        </Col>
      </Row>
    </Container>
  );
}