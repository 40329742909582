import { Route, Routes } from "react-router-dom"

import Home from '../pages/Home'
import Meetings from '../pages/Meetings'
import Meeting from '../pages/Meeting'
import Roles from '../pages/Roles'
import Events from '../pages/Events'
import Profile from '../pages/Profile'
import TandC from '../pages/TandC'
import ContestRandomizer from '../pages/ContestRandomizer'
import Users from "pages/Users"
import Reset from "pages/Reset"
import NotFoundPage from '../pages/404'

const AppRouter = () => (
    <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/profile/' element={<Profile />} />
        <Route path='/profile/:userId' element={<Profile />} />
        <Route path='/meetings/' element={<Meetings />} />
        <Route path='/meeting/:meetingId' element={<Meeting />} />
        <Route path='/roles/' element={<Roles />} />
        <Route path='/events/' element={<Events />} />
        <Route path='/tm-tools/contest-randomizer' element={<ContestRandomizer />} />
        <Route path='/users' element={<Users />} />
        <Route path='/reset/:token' element={<Reset />} />
        <Route path='/t&c/' element={<TandC />} />
        <Route path='*' element={<NotFoundPage />} />
    </Routes>
)

export default AppRouter 