
import { Image, Nav } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
// import { NavDropdown } from "react-bootstrap"
import { authActions } from '_store'
import signOutImg from '../../assets/svg/brand-logos/signout.svg'
import { useState, useEffect } from 'react'

export const Signout = () => {
  const dispatch = useDispatch()

  const { user: authUser } = useSelector(x => x.auth)
  const [avatar, setAvatar] = useState(signOutImg)

  useEffect(() => {
    if (authUser.user.avatar) setAvatar('data:image/png;base64, ' + authUser.user.avatar)
  }, [avatar, authUser.user.avatar])

  const signout = (e) => {
    e.preventDefault()
    dispatch(authActions.logout())
  }

  return (
    <Nav.Item className='nav-item' >
      <Nav.Link className='navbar-link'>
        <Image src={avatar} style={{ width: '80px', height: '80px' }} />
      </Nav.Link>
      <div className='megamenu'>
        <Nav.Link as={Link} to={`/profile/${authUser.user._id}`} className='active navbar-link'> Profile</Nav.Link>
        <div className="megamenu-divider"></div>
        <Nav.Link onClick={signout} className='active navbar-link'> Signout</Nav.Link>
      </div>
    </Nav.Item>
  )
}