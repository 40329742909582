import { Col, Row, Image } from "react-bootstrap";
import userImg from '../../../assets/user.png'

export default function Avatar(profile) {

  // console.log(profile)
  return (
    <Row className='avatar'>
      <Col className='hexagon'>

        {!profile.profile.avatar
          ? <Image src={userImg} style={{ marginTop: '10px', marginLeft: '10px', height: '180px', width: '180px' }} />
          : <Image src={'data:image/png;base64, ' + profile.profile.avatar} />
        }
      </Col>
    </Row>
  )
}