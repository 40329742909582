import { NavBar } from "components/general/NavBar"
import { Footer } from "components/general/Footer"
import EventsComponent from "components/Events/EventsComponent"
import { Row, Col, Container } from 'react-bootstrap'

export default function Events() {

  return (
    <>
      <NavBar pagename="Meetings" />
      <section className="meeting">
        <Container>
          <Row>
            <Col xs={12} >
              <EventsComponent />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  )
}