import { NavBar } from "../components/general/NavBar"
import { Footer } from "../components/general/Footer"
import { Container } from "react-bootstrap"
import Header from "components/Profile/Header"
import Menu from "components/Profile/Menu"
import { useSelector } from "react-redux"
import NotAuthorized from "./NotAuthorized"
import { createContext, useEffect, useState, useCallback } from "react"
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

export const ProfileContext = createContext()

export default function Profile() {

  const { user: authUser } = useSelector(x => x.auth)
  const url = process.env.REACT_APP_URL
  const params = useParams()
  const userId = params.userId
  // console.log(userId)

  const [profile, setProfile] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  const getProfile = useCallback(async () => {

    if (!authUser) return

    setIsLoading(true)

    await fetch(`${url}/users/${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authUser.token
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('(' + res.status + ') We could not retrieve the user')
        }
        setIsLoading(false)
        return res.json()
      })
      .then(resData => {
        setProfile(resData)
      })

    setIsLoading(false)
  }, [url, userId, authUser]
  )

  useEffect(() => {
    if (authUser) {
      getProfile()
    }
  }, [getProfile, authUser])

  if (!authUser) {
    return <NotAuthorized />
  }
  else {
    return (
      <ProfileContext.Provider value={{ profile, setProfile }}>
        {
          isLoading ?
            <Spinner animation='border' variant='danger' />
            :
            <>
              <NavBar pagename='Profile' />
              <section className="profile">
                <Container>
                  <Header />
                  <Menu />
                </Container>
              </section>
              <Footer />
            </>
        }
      </ProfileContext.Provider>
    )
  }
}