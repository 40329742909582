import { Row, Col, Form, Button, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import Select from 'react-select';

export default function AgendaItemAddNewModal({ meetingId, getAgenda, prevPos = null }) {
    const { user: authUser } = useSelector(x => x.auth);
    const url = process.env.REACT_APP_URL;

    const [role, setRole] = useState('');
    const [event, setEvent] = useState('');
    const [meetingRoles, setMeetingRoles] = useState([]);
    const [agendaEvents, setAgendaEvents] = useState([]);
    const [saveLoading, setSaveLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    useEffect(() => {
        fetch(`${url}/meetingroles`, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(data => setMeetingRoles(data))
            .catch(err => console.error(err));
    }, [url]);

    useEffect(() => {
        fetch(`${url}/agendaEvents`, {
            method: 'GET'
        })
            .then(res => res.json())
            .then(data => setAgendaEvents(data))
            .catch(err => console.error(err));
    }, [url]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSaveLoading(true);
        let position = '';
        if (!prevPos) {
            position = 1;
        } else {
            position = Number(prevPos) + 1;
        }
        const validate = true;
        if (validate === true) {
            fetch(url + '/agendaItem/' + meetingId, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authUser.token
                },
                body: JSON.stringify({
                    meetingRole: role,
                    agendaEvent: event,
                    position: position
                })
            })
                .then(res => {
                    if (res.status !== 200 && res.status !== 201) {
                        throw new Error('(' + res.status + ') We could not save your new agenda Item');
                    }
                    return res.json();
                })
                .then(resData => {
                    toast.success('Your AgendaItem ' + event + ' has been created', { position: 'bottom-left' });
                    setSaveLoading(false);
                    getAgenda();
                    handleCloseModal();
                })
                .catch(err => {
                    toast.error('' + err, { position: 'bottom-left' });
                    setSaveLoading(false);
                });
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: '#343a40',
            color: '#fff',
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#343a40',
            color: '#fff',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#fff',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#495057' : state.isFocused ? '#495057' : '#343a40',
            color: '#fff',
        }),
        input: (provided) => ({
            ...provided,
            color: '#fff',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#6c757d',
        }),
    };

    const eventOptions = agendaEvents.map(event => ({ value: event._id, label: event.name }));
    const roleOptions = meetingRoles.map(role => ({ value: role._id, label: role.name }));

    return (
        <>
            <Row>
                <Col className='addAgendaItem'>
                    <h2 onClick={handleShowModal}>Add Item</h2>
                </Col>
            </Row>
            <Modal show={showModal} onHide={handleCloseModal} className='text-center' data-bs-theme={'dark'} style={{ top: '2%' }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add New Agenda Item
                    </Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <Select
                            options={eventOptions}
                            className='mb-3 formInput'
                            name='event'
                            value={eventOptions.find(option => option.value === event)}
                            onChange={option => setEvent(option ? option.value : '')}
                            isClearable
                            isSearchable
                            styles={customStyles}
                            placeholder='Select Event'
                        />
                        <Select
                            options={roleOptions}
                            className='mb-3 formInput'
                            name='role'
                            value={roleOptions.find(option => option.value === role)}
                            onChange={option => setRole(option ? option.value : '')}
                            isClearable
                            isSearchable
                            styles={customStyles}
                            placeholder='Select Role'
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        {saveLoading
                            ?
                            <>
                                <Spinner animation='border' variant='danger' />
                                Adding Agenda Item ...
                            </>
                            :
                            <>
                                <Button variant="success" type='submit' onClick={handleSubmit}>
                                    Add Agenda Item
                                </Button>
                                <Button variant="danger" onClick={handleCloseModal}>
                                    Cancel
                                </Button>
                            </>
                        }
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}