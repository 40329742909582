import { Card, Row, Col, Dropdown } from 'react-bootstrap'
import EditModal from './crudModals/EditModal'
import DeleteModal from './crudModals/DeleteModal'
// import { useSelector } from 'react-redux'

export default function EventsCard({ event, getEvents }) {
  // const { user } = useSelector(x => x.auth)
  return (
    <Col xs={12} md={12} lg={12}>
      <Card className="roles-card">
        <Card.Title><h3>{event.name}</h3></Card.Title>
        <Card.Body>
          <Row>
            <Col xs={12} md={12} lg={12}>
              {event.title}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <div dangerouslySetInnerHTML={{ __html: event.description }}></div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <div dangerouslySetInnerHTML={{ __html: event.subdescription }}></div>
            </Col>
          </Row>
          <Row>
            <Col style={{ backgroundColor: "#146c43", color: "#ffffff", textAlign: "center" }}>{event.green}</Col>
            <Col style={{ backgroundColor: "#ffc107", color: "#000000", textAlign: "center" }}>{event.amber}</Col>
            <Col style={{ backgroundColor: "#dc3545", color: "#ffffff", textAlign: "center" }}>{event.red}</Col>
            <Col style={{ backgroundColor: "#3498db", color: "#ffffff", textAlign: "center" }}>{event.total}</Col>
          </Row>

        </Card.Body>
        <Card.Footer>
          <Dropdown className="roles-card-dropdown" data-bs-theme={'dark'}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <EditModal event={event} getEvents={getEvents} />
              <DeleteModal event={event} getEvents={getEvents} />
            </Dropdown.Menu>
          </Dropdown>
        </Card.Footer>
      </Card>
    </Col >
  )
}